<template>
  <div class="page-wrapper">
    <div class="content-wrapper pa-6" @scroll="onScroll" ref="wrapper">
      <h2
        v-if="
          !currentCompanyPermissions[permissions.FULL_CUSTOMER_DATA] &&
          !currentCompanyPermissions[permissions.RESTRICTED_CUSTOMER_DATA]
        "
      >
        {{ $t('device.deviceList') }}
      </h2>
      <div v-else class="d-flex">
        <router-link
          :to="{ name: 'DeviceListPageListView', query: { ...this.$route.query } }"
          class="list-map-link mr-8"
        >
          {{ $t('device.listView') }}
        </router-link>
        <router-link
          :to="{ name: 'DeviceListPageMapView', query: { ...this.$route.query } }"
          class="list-map-link"
        >
          {{ $t('device.mapView') }}
        </router-link>
      </div>
      <div>
        <v-text-field
          v-model="search"
          :label="$t('global.doFilter')"
          class="mx-4"
          clearable
          hide-details
        ></v-text-field>
        <v-tabs v-model="tabFilter" show-arrows class="mt-5" ref="tabs">
          <v-tab
            >{{ `${$t('global.all')} ` }} ({{ filteredAllDevicesCount
            }}{{
              filteredAllDevicesCount !== allDevicesCount
                ? ` ${$t('global.dataTable.of')} ${allDevicesCount}`
                : ''
            }})
          </v-tab>
          <v-tab
            >{{ `${$t('device.status.ONLINE')} ` }} ({{ filteredDevicesOnlineCount
            }}{{
              filteredDevicesOnlineCount !== devicesOnlineCount
                ? ` ${$t('global.dataTable.of')} ${devicesOnlineCount}`
                : ''
            }})
          </v-tab>
          <v-tab
            >{{ `${$t('device.status.OFFLINE')} ` }} ({{ filteredDevicesOfflineCount
            }}{{
              filteredDevicesOfflineCount !== devicesOfflineCount
                ? ` ${$t('global.dataTable.of')} ${devicesOfflineCount}`
                : ''
            }})
          </v-tab>
          <v-tab
            >{{ `${$t('device.deviceListHeaders.reports')} ` }} ({{ filteredDevicesWithReportsCount
            }}{{
              filteredDevicesWithReportsCount !== devicesWithReportsCount
                ? ` ${$t('global.dataTable.of')} ${devicesWithReportsCount}`
                : ''
            }})
          </v-tab>
        </v-tabs>
      </div>
      <div class="flex-grow-1">
        <router-view :userDevices="tableData" :isLoading="$apollo.loading"></router-view>
        <div
          v-if="!lastPageReached && !$apollo.loading"
          @click="tryToLoadMore"
          class="mt-5"
          style="text-align: center; cursor: pointer; text-decoration: underline"
        >
          {{ $t('global.loadMore') }}
        </div>
        <!-- If not last page reached, but apollo is loading, show spinner -->
        <div class="d-flex justify-center">
          <v-progress-circular
            v-if="!lastPageReached && $apollo.loading"
            indeterminate
            class="mt-5"
          ></v-progress-circular>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userDevices, devicesCount, filteredDevicesCount } from '@/graphql/query/device'
import { mapGetters } from 'vuex'
import { permissions } from '@/config/permissions'

const queryParamName = 'tab'
const tabQueryNameMap = {
  1: 'online',
  2: 'offline',
  3: 'reports',
}

export default {
  name: 'DeviceListPage',
  data() {
    return {
      search: '',
      tabFilter: 0,
      devices: [],
      pageNumber: 1,
      targetPageNumber: 1,
      lastPageReached: false,
      searchDebounceTimer: null,
      connectionStatus: null,
    }
  },
  apollo: {
    userDevices: {
      query: userDevices,
      errorPolicy: 'ignore',
      variables() {
        return {
          type: this.$route.params.type.toUpperCase(),
          companyId: this.currentCompanyId,
          searchText: this.search.trim().toLowerCase().normalize('NFKD').replace(/[^\w]/g, ''),
          pageNumber: this.targetPageNumber,
          connectionStatus: this.connectionStatus,
          withEvents: this.tabFilter === 3 ? true : null,
          withCompanyName:
            this.currentCompanyPermissions[permissions.OTHER_COMPANIES_MANAGEMENT] ||
            !this.currentCompanyId,
          withCustomName: !this.currentCompanyId,
          withAddress: this.$route.name === 'DeviceListPageMapView',
          lang: this.$i18n.locale,
        }
      },
      skip() {
        return this.$route.params.type == null
      },
      update(data) {
        if (this.targetPageNumber == 1) this.devices = data.me.devices
        else this.devices = this.devices.concat(data.me.devices)
        this.pageNumber = this.targetPageNumber
        if (data.me.devices < 20) this.lastPageReached = true
        this.checkIsTooFewLoaded()
        return this.devices
      },
    },
    devicesCount: {
      query: devicesCount,
      errorPolicy: 'ignore',
      variables() {
        return {
          type: this.$route.params.type.toUpperCase(),
          companyId: this.currentCompanyId,
        }
      },
      skip() {
        return this.$route.params.type == null
      },
      update(data) {
        return data
      },
    },
    filteredDevicesCount: {
      query: filteredDevicesCount,
      errorPolicy: 'ignore',
      variables() {
        return {
          type: this.$route.params.type.toUpperCase(),
          companyId: this.currentCompanyId,
          searchText: this.search.trim().toLowerCase().normalize('NFKD').replace(/[^\w]/g, ''),
        }
      },
      skip() {
        return this.$route.params.type == null
      },
      update(data) {
        return data
      },
    },
  },
  mounted() {
    if (this.$route.query[queryParamName]) {
      const tab = Object.keys(tabQueryNameMap).find(
        (tab) => tabQueryNameMap[tab] === this.$route.query[queryParamName],
      )
      if (tab) {
        this.tabFilter = Number(tab)
      } else {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            tab: undefined,
          },
        })
      }
    }
    if (this.$route.query.q) {
      this.search = this.$route.query.q
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.pageNumber = 1
    this.targetPageNumber = 1
    this.lastPageReached = false
    next()
  },
  methods: {
    checkIsTooFewLoaded() {
      this.$nextTick(() => {
        const info = this.$refs.wrapper
        if (info.scrollTop + info.clientHeight + 100 > info.scrollHeight) {
          this.tryToLoadMore()
        }
      })
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        this.tryToLoadMore()
      }
    },
    tryToLoadMore() {
      if (!this.lastPageReached && this.targetPageNumber == this.pageNumber) {
        this.targetPageNumber++
        this.$apollo.queries.userDevices.refetch()
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyPermissions', 'currentCompanyId']),
    filteredDevices() {
      if (!this.devices) {
        return []
      }
      return this.devices
    },
    tableData() {
      const conditionMap = {
        0: () => true, // tab 0 - ALL devices
        1: (device) => device.connectionStatus === 'ONLINE', // tab 1 - ONLINE devices
        2: (device) => device.connectionStatus === 'OFFLINE', // tab 2 - OFFLINE devices
        3: (device) => device.eventDetectionPoints?.length, // tab 3 - devices with reports(failures)
      }
      return this.filteredDevices ? this.filteredDevices.filter(conditionMap[this.tabFilter]) : []
    },
    allDevicesCount() {
      return this.devicesCount?.allDevicesCount || 0
    },
    filteredAllDevicesCount() {
      return this.filteredDevicesCount?.filteredDevicesCount || 0
    },
    devicesOnlineCount() {
      return this.devicesCount?.onlineDevicesCount || 0
    },
    filteredDevicesOnlineCount() {
      return this.filteredDevicesCount?.filteredOnlineDevicesCount || 0
    },
    devicesOfflineCount() {
      return this.devicesCount?.offlineDevicesCount || 0
    },
    filteredDevicesOfflineCount() {
      return this.filteredDevicesCount?.filteredOfflineDevicesCount || 0
    },
    devicesWithReportsCount() {
      return this.devicesCount?.withEventsDevicesCount || 0
    },
    filteredDevicesWithReportsCount() {
      return this.filteredDevicesCount?.filteredWithEventsDevicesCount || 0
    },
    permissions() {
      return permissions
    },
  },
  watch: {
    tableData() {
      this.$store.commit('user/setUsersDevices', this.tableData)
    },
    tabFilter() {
      if (this.$route.query[queryParamName] !== tabQueryNameMap[this.tabFilter]) {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            [queryParamName]: tabQueryNameMap[this.tabFilter],
          },
        })
      }
      this.connectionStatus = this.tabFilter == 1 ? true : this.tabFilter == 2 ? false : null
    },
    search(newSearch) {
      if (this.searchDebounceTimer) {
        clearTimeout(this.searchDebounceTimer) // Clear the previous timer
      }

      this.searchDebounceTimer = setTimeout(() => {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            ...this.$route.query,
            q: newSearch && newSearch.trim() !== '' ? newSearch : undefined,
          },
        })
      }, 500) // Set a new timer for 500 ms
    },
    devicesOnline() {
      // https://github.com/vuetifyjs/vuetify/issues/4733
      // fix tab indicator when tab labels change
      this.$refs.tabs.onResize()
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.page-wrapper {
  display: flex;

  .content-wrapper {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    .list-map-link {
      font-size: 24px;
      font-weight: 500;
      color: #454d57;
      opacity: 0.4;

      &:hover {
        text-decoration: none;
      }

      &.router-link-active {
        opacity: 1;
      }
    }
  }

  /deep/.v-input {
    margin-left: 0 !important;
    margin-right: 0 !important;

    max-width: 300px;
  }

  /deep/.v-slide-group {
    background-color: fade(@color-brand-highlight, 50%) !important;
    margin-bottom: 30px;

    .v-tab {
      color: @color-brand-2 !important;
      opacity: 0.5;

      &--active {
        opacity: 1;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .v-tabs-slider-wrapper {
      color: @color-brand-2 !important  ;
    }
  }
}
</style>
