<template>
  <div class="error-code-detail-wrapper">
    <div v-if="$apollo.queries.coreCause.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="coreCause">
      <div class="mb-2 d-flex align-center justify-space-between">
        <h3 class="mr-2">{{ $t('global.eventCoreCauseDetail.title') }}</h3>
        <v-btn
          @click="confirmDeleteModalOpen = true"
          class="ml-2"
          color="error"
          style="height: 27px"
          depressed
        >
          {{ $t('global.delete') }}
        </v-btn>
      </div>
      <div class="values-wrapper mb-4">
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'name'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ this.$t('global.coreCause') }}</span>
          <span class="value--text">{{ coreCause.name }}</span>
        </div>
      </div>
      <h4>{{ $t('global.descriptions') }}</h4>
      <div class="values-wrapper mb-4">
        <div class="value" v-for="lang of allowedLanguages" :key="lang">
          <v-btn
            small
            icon
            class="edit-btn"
            @click="editModalOpen = `descriptionTranslations.${lang}`"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span
            ><v-icon size="18">{{ `$${lang}` }}</v-icon></span
          >
          <span class="value--text">{{ coreCause[`${lang}Description`] || '---' }}</span>
        </div>
      </div>
      <div class="mb-4 d-flex align-center flex-wrap">
        <h3 class="mr-5">{{ $t('global.correctiveActions') }}</h3>
        <v-btn height="27" depressed :to="{ name: 'AdminCorrectiveActions' }">{{
          $t('global.manage')
        }}</v-btn>
      </div>
      <div class="assignment-section">
        <div class="available-actions-wrapper">
          <h4 class="mb-5">{{ $t('global.eventCoreCauseDetail.availableActions') }}</h4>
          <v-text-field
            v-model="searchAvailable"
            :label="$t('users.search')"
            clearable
            class="mx-4"
          ></v-text-field>
          <div class="d-flex flex-column">
            <div
              v-for="correctiveAction of filteredAvailableCorrectiveActions"
              :key="correctiveAction.id"
              class="available-action"
            >
              <div class="texts-wrapper">
                <router-link
                  :to="{
                    name: 'AdminCorrectiveActionDetail',
                    params: { id: correctiveAction.id },
                  }"
                >
                  <span class="description">{{ correctiveAction.description }}</span>
                  <span class="name">{{ correctiveAction.name }}</span>
                </router-link>
              </div>
              <v-btn icon @click="assignCorrectiveAction(correctiveAction.id)"
                ><v-icon>$plus</v-icon></v-btn
              >
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-5">{{ $t('global.eventCoreCauseDetail.assignedActions') }}</h4>
          <div class="d-flex flex-column" ref="sortableCorrectiveActions">
            <div
              v-for="correctiveAction of correctiveActions"
              :key="correctiveAction.id"
              class="assigned-action"
            >
              <v-icon class="sort-handle">mdi-drag-horizontal-variant</v-icon>
              <div class="texts-wrapper">
                <router-link
                  :to="{
                    name: 'AdminCorrectiveActionDetail',
                    params: { id: correctiveAction.id },
                  }"
                >
                  <span class="description">{{ correctiveAction.description }}</span>
                  <span class="name">{{ correctiveAction.name }}</span>
                </router-link>
              </div>
              <v-btn
                icon
                @click="openUnassignCorrectiveActionConfirmationDialog(correctiveAction)"
                color="error"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4 mt-6 d-flex align-center flex-wrap">
        <h3 class="mr-5">{{ $t('global.eventCoreCauseDetail.assignedToEventCodes') }}</h3>
        <v-btn height="27" depressed :to="{ name: 'AdminEventCodes' }">{{
          $t('global.manage')
        }}</v-btn>
      </div>
      <div class="assigned-to-wrapper">
        <AssignedToList :data="coreCause.eventCodes" :detailPageName="'AdminEventCodeDetail'" />
      </div>
    </div>
    <div v-else class="pa-8">
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ $t('global.eventCoreCauseDetail.coreCauseNotFound') }}
          </v-col>
          <v-col class="shrink">
            <v-btn @click="$router.back()">{{ $t('global.back') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <template v-if="coreCause">
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'name'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.name')}`"
        :validations="requiredValidation"
        :originalValue="coreCause.name"
        :submit="updateCoreCause"
      >
        <v-text-field
          :value="value"
          @input="onInput"
          :error-messages="errors"
          :label="$t('global.name')"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
        ></v-text-field>
      </EditValueModal>
      <EditValueModal
        v-for="lang of allowedLanguages"
        :key="lang"
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === `descriptionTranslations.${lang}`"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.description')}`"
        :validations="maxLengthValidation"
        :originalValue="coreCause[`${lang}Description`]"
        :submit="updateCoreCause"
      >
        <v-textarea
          :value="value"
          :error-messages="errors"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          counter="255"
          rows="2"
          style="width: 330px"
        >
          <template v-slot:label>
            {{ $t('global.adminEventCode.description') }}
            <v-icon class="ml-1" style="vertical-align: middle">{{ `$${lang}` }}</v-icon>
          </template>
        </v-textarea>
      </EditValueModal>

      <ConfirmationDialog
        v-model="confirmDeleteModalOpen"
        :heading="$t('global.eventCoreCauseDetail.deleteConfirmationModal.heading')"
        :text="
          $t('global.eventCoreCauseDetail.deleteConfirmationModal.text', {
            coreCause: coreCause.name,
          })
        "
        :confirmText="$t('global.delete')"
        confirmBtnColor="error"
        :action="deleteCoreCause"
      />
    </template>

    <ConfirmationDialog
      v-if="correctiveActionToUnassign"
      v-model="unassignCorrectiveActionConfirmationModalOpen"
      :heading="$t('global.eventCoreCauseDetail.unassignCorrectiveActionConfirmationModal.heading')"
      :text="
        $t('global.eventCoreCauseDetail.unassignCorrectiveActionConfirmationModal.text', {
          correctiveAction: correctiveActionToUnassign.name,
        })
      "
      :confirmText="$t('global.unassign')"
      confirmBtnColor="error"
      :action="unassignCorrectiveAction"
    />
  </div>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { eventCoreCauseAdmin } from '@/graphql/query/eventCoreCauseAdmin'
import { eventCorrectiveActionsAdmin } from '@/graphql/query/eventCorrectiveActionsAdmin'
import { coreCauseAssignCorrectiveAction } from '@/graphql/mutations/coreCauseAssignCorrectiveAction'
import { coreCauseUnassignCorrectiveAction } from '@/graphql/mutations/coreCauseUnassignCorrectiveAction'
import { coreCauseReorderCorrectiveActions } from '@/graphql/mutations/coreCauseReorderCorrectiveActions'
import { coreCauseDelete } from '@/graphql/mutations/coreCauseDelete'
import { coreCauseUpdate } from '@/graphql/mutations/coreCauseUpdate'
import EditValueModal from '@/components/Admin/ServiceHints/EditValueModal'
import AssignedToList from '@/components/Admin/ServiceHints/AssignedToList'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import Sortable from 'sortablejs'
import { allowedLanguages } from '@/translations'
import set from 'lodash/set'

export default {
  name: 'AdminCoreCauseDetailPage',
  components: { EditValueModal, AssignedToList, ConfirmationDialog },
  props: {
    id: String,
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      correctiveActions: null,
      confirmDeleteModalOpen: false,
      correctiveActionToUnassign: null,
      unassignCorrectiveActionConfirmationModalOpen: false,
      editModalOpen: null,
      requiredValidation: {
        value: { required },
      },
      maxLengthValidation: {
        value: { maxLength: maxLength(255) },
      },
      searchAvailable: null,
    }
  },
  apollo: {
    coreCause: {
      query: eventCoreCauseAdmin,
      variables() {
        return {
          id: this.id,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        this.correctiveActions = JSON.parse(
          JSON.stringify(data.eventCoreCauseAdmin.correctiveActions),
        )
        return data.eventCoreCauseAdmin
      },
    },
    eventCorrectiveActionsAdmin: {
      query: eventCorrectiveActionsAdmin,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
    },
  },
  methods: {
    async assignCorrectiveAction(newCorrectiveActionId) {
      try {
        await this.$apollo.mutate({
          mutation: coreCauseAssignCorrectiveAction,
          variables: {
            coreCauseId: this.id,
            correctiveActionId: newCorrectiveActionId,
          },
          refetchQueries: ['eventCoreCauseAdmin'],
        })

        this.$toast.success(
          this.$t('global.eventCoreCauseDetail.assignCorrectiveActionAction.success'),
        )
      } catch (error) {
        console.error(error)
        this.$toast.error(
          this.$t('global.eventCoreCauseDetail.assignCorrectiveActionAction.failure'),
        )
      }
    },
    async updateCoreCause(value) {
      const key = this.editModalOpen
      try {
        const newValue = value?.trim() === '' ? null : value

        const data = {
          coreCauseId: this.id,
          name: this.coreCause.name,
          descriptionTranslations: Object.fromEntries(
            allowedLanguages.map((lang) => [lang, this.coreCause[`${lang}Description`]]),
          ),
        }

        // update key
        set(data, key, newValue)

        await this.$apollo.mutate({
          mutation: coreCauseUpdate,
          variables: data,
          refetchQueries: ['eventCoreCauseAdmin'],
        })

        this.$toast.success(this.$t('global.eventCoreCauseDetail.updateCoreCauseAction.success'))
        this.editModalOpen = null
      } catch (error) {
        console.error(error)
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        this.$toast.error(
          parsedError === 'CORE_CAUSE_UPDATE_NAME_TAKEN'
            ? this.$t('global.nameTakenError')
            : this.$t('global.eventCoreCauseDetail.updateCoreCauseAction.failure'),
        )
      }
    },
    async deleteCoreCause() {
      try {
        await this.$apollo.mutate({
          mutation: coreCauseDelete,
          variables: {
            coreCauseId: this.id,
          },
          refetchQueries: this.inModal ? ['serviceHintsAdminOverview'] : [],
        })

        this.$toast.success(this.$t('global.eventCoreCauseDetail.deleteCoreCauseAction.success'))
        if (this.inModal) {
          this.$emit('onDelete')
        } else {
          this.$router.push({ name: 'AdminCoreCauses' })
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eventCoreCauseDetail.deleteCoreCauseAction.failure'))
      }
    },
    async unassignCorrectiveAction() {
      try {
        await this.$apollo.mutate({
          mutation: coreCauseUnassignCorrectiveAction,
          variables: {
            coreCauseId: this.id,
            correctiveActionId: this.correctiveActionToUnassign.id,
          },
          refetchQueries: ['eventCoreCauseAdmin'],
        })

        this.$toast.success(
          this.$t('global.eventCoreCauseDetail.unassignCorrectiveActionAction.success'),
        )
        this.unassignCorrectiveActionConfirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(
          this.$t('global.eventCoreCauseDetail.unassignCorrectiveActionAction.failure'),
        )
      }
    },
    async reorderCorrectiveActions(orderedCorrectiveActionIds) {
      try {
        await this.$apollo.mutate({
          mutation: coreCauseReorderCorrectiveActions,
          variables: {
            coreCauseId: this.id,
            orderedCorrectiveActionIds,
          },
          refetchQueries: this.inModal ? ['serviceHintsAdminOverview'] : [],
        })

        this.$toast.success(
          this.$t('global.eventCoreCauseDetail.reorderCorrectiveActionsAction.success'),
        )
      } catch (error) {
        console.error(error)
        this.$toast.error(
          this.$t('global.eventCoreCauseDetail.reorderCorrectiveActionsAction.failure'),
        )
      }
    },
    async saveOrder(event) {
      const movedItem = this.correctiveActions.splice(event.oldIndex, 1)[0]
      this.correctiveActions.splice(event.newIndex, 0, movedItem)

      await this.reorderCorrectiveActions(this.correctiveActions.map((ca) => ca.id))
    },
    openUnassignCorrectiveActionConfirmationDialog(correctiveAction) {
      this.correctiveActionToUnassign = correctiveAction
      this.unassignCorrectiveActionConfirmationModalOpen = true
    },
  },
  computed: {
    availableCorrectiveActions() {
      if (!this.eventCorrectiveActionsAdmin || !this.correctiveActions) {
        return []
      }
      return this.eventCorrectiveActionsAdmin.filter(
        (ca) =>
          !this.correctiveActions.find((assignedCorrAction) => assignedCorrAction.id === ca.id),
      )
    },
    filteredAvailableCorrectiveActions() {
      if (!this.availableCorrectiveActions.length) {
        return []
      }
      if (!this.searchAvailable || this.searchAvailable.trim() === '') {
        return this.availableCorrectiveActions
      }
      const lowerCaseSearch = this.searchAvailable.toLowerCase()
      return this.availableCorrectiveActions.filter(
        (ca) =>
          ca.name.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
          (ca.description && ca.description.toLowerCase().indexOf(lowerCaseSearch) !== -1),
      )
    },
    allowedLanguages() {
      return allowedLanguages
    },
  },
  watch: {
    correctiveActions(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$nextTick(() => {
          Sortable.create(this.$refs.sortableCorrectiveActions, {
            animation: 150,
            handle: '.sort-handle',
            onUpdate: this.saveOrder,
          })
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.values-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 5px;
}

.value {
  padding: 16px;
  background-color: @color-tile-background;
  position: relative;

  &--headline {
    display: block;
    opacity: 0.5;
    font-size: 12px;
    margin-right: 20px;
  }

  &--text {
    display: block;
    font-size: 14px;
    font-weight: bold;
    word-break: break-word;
  }

  .edit-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.assignment-section {
  display: flex;

  .available-actions-wrapper {
    margin-right: 30px;
  }

  .available-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px 10px 15px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    background-color: tint(@color-brand-highlight, 50%);
    margin-bottom: 5px;
    word-break: break-word;
  }

  .assigned-action {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 15px;
    font-size: 14px;
    font-weight: 500;
    background-color: @color-brand-highlight;
    margin-bottom: 5px;
    word-break: break-word;

    & > i {
      cursor: move;
      margin-right: 15px;
    }
  }

  .texts-wrapper {
    flex-grow: 1;
    margin-right: 5px;

    .description {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 3px;
    }

    .name {
      display: block;
      font-size: 12px;
      opacity: 0.75;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .available-actions-wrapper {
      order: 2;
      margin-right: 0px;
      margin-top: 20px;
    }
  }
}

.assigned-to-wrapper {
  display: flex;

  @media (max-width: 600px) {
    display: unset;
  }
}
</style>
