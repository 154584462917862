<template>
  <div class="error-code-detail-wrapper">
    <div v-if="$apollo.queries.eventCode.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else-if="eventCode">
      <div class="mb-2 d-flex align-center justify-space-between">
        <h3 class="mr-2">{{ $t('global.eventCodeDetail.title') }}</h3>
        <div class="d-flex">
          <v-btn
            @click="updateEventCodeModalOpen = true"
            color="primary"
            style="text-decoration: none; height: 27px"
            depressed
          >
            {{ $t('global.eventCodeDetail.editAll') }}
          </v-btn>
          <v-btn
            @click="confirmDeleteModalOpen = true"
            class="ml-2"
            color="error"
            style="height: 27px"
            depressed
          >
            {{ $t('global.delete') }}
          </v-btn>
        </div>
      </div>
      <div class="values-wrapper mb-4">
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'name'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ this.$t('global.id') }}</span>
          <span class="value--text">{{ eventCode.name }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'severity'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.adminEventCode.severity') }}</span>
          <span class="value--text">{{ eventCode.severity }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'eventDetectionPointId'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.adminEventCode.detectionPoint') }}</span>
          <span class="value--text">{{
            eventCode.detectionPoint ? eventCode.detectionPoint.name : '---'
          }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'disabled'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.state') }}</span>
          <span class="value--text">{{
            eventCode.disabled
              ? $t('global.adminEventCode.disabled')
              : $t('global.adminEventCode.active')
          }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'defaultNotificationClass'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.defaultNotificationClass') }}</span>
          <NotificationClass
            v-if="eventCode.defaultNotificationClass != null"
            class="mt-1"
            :notificationClass="eventCode.defaultNotificationClass.notificationClass"
          />
          <span v-else class="value--text">---</span>
        </div>
      </div>
      <h4>{{ $t('global.descriptions') }}</h4>
      <div class="values-wrapper mb-4">
        <div class="value" v-for="lang of allowedLanguages" :key="lang">
          <v-btn
            small
            icon
            class="edit-btn"
            @click="editModalOpen = `descriptionTranslations.${lang}`"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span
            ><v-icon size="18">{{ `$${lang}` }}</v-icon></span
          >
          <span class="value--text">{{ eventCode[`${lang}Description`] || '---' }}</span>
        </div>
      </div>
      <div class="long-values-wrapper mb-5">
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'detectionMechanism'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.adminEventCode.detectionMechanism') }}</span>
          <span class="value--text">{{ eventCode.detectionMechanism || '---' }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'terminationPoint'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.adminEventCode.terminationPoint') }}</span>
          <span class="value--text">{{ eventCode.terminationPoint || '---' }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'terminationMechanism'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{
            $t('global.adminEventCode.terminationMechanism')
          }}</span>
          <span class="value--text">{{ eventCode.terminationMechanism || '---' }}</span>
        </div>
        <div class="value">
          <v-btn small icon class="edit-btn" @click="editModalOpen = 'actionUponDetection'">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <span class="value--headline">{{ $t('global.adminEventCode.actionUponDetection') }}</span>
          <span class="value--text">{{ eventCode.actionUponDetection || '---' }}</span>
        </div>
      </div>
      <div class="mb-4 d-flex align-center flex-wrap">
        <h3 class="mr-5">{{ $t('global.coreCauses') }}</h3>
        <v-btn height="27" depressed :to="{ name: 'AdminCoreCauses' }">{{
          $t('global.manage')
        }}</v-btn>
      </div>
      <div class="assignment-section">
        <div class="available-causes-wrapper">
          <h4 class="mb-5">{{ $t('global.adminEventCode.availableCauses') }}</h4>
          <v-text-field
            v-model="searchAvailable"
            :label="$t('users.search')"
            clearable
            class="mx-4"
          ></v-text-field>
          <div class="d-flex flex-column">
            <div
              v-for="coreCause of filteredAvailableCoreCauses"
              :key="coreCause.id"
              class="available-cause"
            >
              <div class="texts-wrapper">
                <router-link :to="{ name: 'AdminCoreCauseDetail', params: { id: coreCause.id } }">
                  <span class="description">{{ coreCause.description }}</span>
                  <span class="name">{{ coreCause.name }}</span>
                </router-link>
              </div>
              <v-btn icon @click="assignCoreCause(coreCause.id)"><v-icon>$plus</v-icon></v-btn>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-5">{{ $t('global.adminEventCode.assignedCauses') }}</h4>
          <div class="d-flex flex-column" ref="sortableCoreCauses">
            <div v-for="coreCause of coreCauses" :key="coreCause.id" class="assigned-cause">
              <v-icon class="sort-handle">mdi-drag-horizontal-variant</v-icon>
              <div class="texts-wrapper">
                <router-link :to="{ name: 'AdminCoreCauseDetail', params: { id: coreCause.id } }">
                  <span class="description">{{ coreCause.description }}</span>
                  <span class="name">{{ coreCause.name }}</span>
                </router-link>
              </div>
              <v-btn icon @click="openUnassignCoreCauseConfirmationDialog(coreCause)" color="error"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pa-8">
      <v-alert prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ $t('global.eventCodeDetail.eventCodeNotFound') }}
          </v-col>
          <v-col class="shrink">
            <v-btn @click="$router.back()">{{ $t('global.back') }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <template v-if="eventCode">
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'name'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.id')}`"
        :validations="requiredValidation"
        :originalValue="eventCode.name"
        :submit="updateEventCode"
      >
        <v-text-field
          :value="value"
          @input="onInput"
          :error-messages="errors"
          :label="$t('global.id')"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
        ></v-text-field>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'severity'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.severity')}`"
        :validations="requiredValidation"
        :originalValue="eventCode.severity"
        :submit="updateEventCode"
      >
        <v-select
          :items="errorSeverities"
          :error-messages="errors"
          :label="$t('global.adminEventCode.severity')"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
        ></v-select>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'eventDetectionPointId'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.detectionPoint')}`"
        :validations="requiredValidation"
        :originalValue="eventCode.detectionPoint.id"
        :submit="updateEventCode"
      >
        <v-select
          :items="detectionPoints"
          :error-messages="errors"
          :label="$t('global.adminEventCode.detectionPoint')"
          item-text="name"
          item-value="id"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
        ></v-select>
      </EditValueModal>
      <EditValueModal
        v-slot="{ value, onInput }"
        :isOpen="editModalOpen === 'disabled'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.state')}`"
        :originalValue="eventCode.disabled"
        :submit="updateEventCode"
      >
        <v-checkbox
          class="mt-3"
          :input-value="value"
          @change="onInput"
          :label="$t('global.adminEventCode.disabled')"
        ></v-checkbox>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'defaultNotificationClass'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.defaultNotificationClass')}`"
        :validations="requiredValidation"
        :originalValue="eventCode.defaultNotificationClass.notificationClass"
        :submit="updateEventCode"
      >
        <v-select
          :items="notificationClasses"
          :error-messages="errors"
          :label="$t('global.defaultNotificationClass')"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
        >
          <template v-slot:selection="{ item }">
            <NotificationClass class="mt-2" :notificationClass="item" />
          </template>
          <template v-slot:item="{ active, item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <NotificationClass :notificationClass="item" />
            </v-list-item>
          </template>
        </v-select>
      </EditValueModal>
      <EditValueModal
        v-for="lang of allowedLanguages"
        :key="lang"
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === `descriptionTranslations.${lang}`"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.description')}`"
        :validations="maxLengthValidation"
        :originalValue="eventCode[`${lang}Description`]"
        :submit="updateEventCode"
      >
        <v-textarea
          :value="value"
          :error-messages="errors"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          counter="255"
          rows="2"
          style="width: 330px"
        >
          <template v-slot:label>
            {{ $t('global.adminEventCode.description') }}
            <v-icon class="ml-1" style="vertical-align: middle">{{ `$${lang}` }}</v-icon>
          </template>
        </v-textarea>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'detectionMechanism'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.detectionMechanism')}`"
        :validations="maxLengthValidation"
        :originalValue="eventCode.detectionMechanism"
        :submit="updateEventCode"
      >
        <v-textarea
          :label="$t('global.adminEventCode.detectionMechanism')"
          :error-messages="errors"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
          counter="255"
        ></v-textarea>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'terminationPoint'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.terminationPoint')}`"
        :validations="maxLengthValidation"
        :originalValue="eventCode.terminationPoint"
        :submit="updateEventCode"
      >
        <v-textarea
          :label="$t('global.adminEventCode.terminationPoint')"
          :error-messages="errors"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
          counter="255"
        ></v-textarea>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'terminationMechanism'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.terminationMechanism')}`"
        :validations="maxLengthValidation"
        :originalValue="eventCode.terminationMechanism"
        :submit="updateEventCode"
      >
        <v-textarea
          :label="$t('global.adminEventCode.terminationMechanism')"
          :error-messages="errors"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
          counter="255"
        ></v-textarea>
      </EditValueModal>
      <EditValueModal
        v-slot="{ validations, value, onInput, errors }"
        :isOpen="editModalOpen === 'actionUponDetection'"
        @modalToggle="editModalOpen = null"
        :heading="`${$t('global.edit')}: ${$t('global.adminEventCode.actionUponDetection')}`"
        :validations="maxLengthValidation"
        :originalValue="eventCode.actionUponDetection"
        :submit="updateEventCode"
      >
        <v-textarea
          :label="$t('global.adminEventCode.actionUponDetection')"
          :error-messages="errors"
          :value="value"
          @input="onInput"
          @blur="validations.value.$touch()"
          filled
          style="width: 330px"
          counter="255"
        ></v-textarea>
      </EditValueModal>

      <ConfirmationDialog
        v-model="confirmDeleteModalOpen"
        :heading="$t('global.eventCodeDetail.deleteConfirmationModal.heading')"
        :text="
          $t('global.eventCodeDetail.deleteConfirmationModal.text', { eventCode: eventCode.name })
        "
        :confirmText="$t('global.delete')"
        confirmBtnColor="error"
        :action="deleteEventCode"
      />
    </template>

    <ConfirmationDialog
      v-if="coreCauseToUnassign"
      v-model="unassignCoreCauseConfirmationModalOpen"
      :heading="$t('global.eventCodeDetail.unassignCoreCauseConfirmationModal.heading')"
      :text="
        $t('global.eventCodeDetail.unassignCoreCauseConfirmationModal.text', {
          coreCause: coreCauseToUnassign.name,
        })
      "
      :confirmText="$t('global.unassign')"
      confirmBtnColor="error"
      :action="unassignCoreCause"
    />

    <UpdateEventCodeModal
      v-model="updateEventCodeModalOpen"
      :eventCode="eventCode"
      :refetchQueries="
        inModal ? ['eventCodeAdmin', 'serviceHintsAdminOverview'] : ['eventCodeAdmin']
      "
    />
  </div>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { eventCodeAdmin } from '@/graphql/query/eventCodeAdmin'
import { eventCoreCausesAdmin } from '@/graphql/query/eventCoreCausesAdmin'
import { eventCodeAssignCoreCause } from '@/graphql/mutations/eventCodeAssignCoreCause'
import { eventCodeUnassignCoreCause } from '@/graphql/mutations/eventCodeUnassignCoreCause'
import { eventCodeReorderCoreCauses } from '@/graphql/mutations/eventCodeReorderCoreCauses'
import { eventCodeDelete } from '@/graphql/mutations/eventCodeDelete'
import UpdateEventCodeModal from '@/components/Admin/ServiceHints/UpdateEventCodeModal'
import EditValueModal from '@/components/Admin/ServiceHints/EditValueModal'
import { eventCodeUpdate } from '@/graphql/mutations/eventCodeUpdate'
import pick from 'lodash/pick'
import { errorsConfig } from '@/config/errorsConfig'
import { eventDetectionPointsAdmin } from '@/graphql/query/eventDetectionPointsAdmin'
import Sortable from 'sortablejs'
import { allowedLanguages } from '@/translations'
import set from 'lodash/set'
import NotificationClass from '@/components/NotificationDispatcher/NotificationClass'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { notificationClassesEnumValues } from '@/graphql/query/notificationClassesEnumValues'

export default {
  name: 'AdminEventCodeDetailPage',
  components: { UpdateEventCodeModal, EditValueModal, NotificationClass, ConfirmationDialog },
  props: {
    id: String,
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      coreCauses: null,
      confirmDeleteModalOpen: false,
      coreCauseToUnassign: null,
      unassignCoreCauseConfirmationModalOpen: false,
      updateEventCodeModalOpen: false,
      editModalOpen: null,
      requiredValidation: {
        value: { required },
      },
      maxLengthValidation: {
        value: { maxLength: maxLength(255) },
      },
      searchAvailable: null,
    }
  },
  apollo: {
    eventCode: {
      query: eventCodeAdmin,
      variables() {
        return {
          id: this.id,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        this.coreCauses = JSON.parse(JSON.stringify(data.eventCodeAdmin.coreCauses))
        return data.eventCodeAdmin
      },
    },
    eventCoreCausesAdmin: {
      query: eventCoreCausesAdmin,
      variables() {
        return {
          lang: this.$i18n.locale,
        }
      },
    },
    detectionPoints: {
      query: eventDetectionPointsAdmin,
      update(data) {
        return data.eventDetectionPointsAdmin
      },
    },
    notificationClasses: {
      query: notificationClassesEnumValues,
      update(data) {
        return data.__type.enumValues.map((value) => value.name)
      },
    },
  },
  methods: {
    async updateEventCode(value) {
      const key = this.editModalOpen
      try {
        const nullInsteadOfEmptyString = (string) => (string?.trim() === '' ? null : string)
        const newValue = [
          'detectionMechanism',
          'terminationPoint',
          'terminationMechanism',
          'actionUponDetection',
          ...allowedLanguages.map((lang) => `descriptionTranslations.${lang}`),
        ].includes(key)
          ? nullInsteadOfEmptyString(value)
          : value

        const data = {
          eventCodeId: this.eventCode.id,
          eventDetectionPointId: this.eventCode.detectionPoint?.id,
          defaultNotificationClass:
            this.eventCode.defaultNotificationClass?.notificationClass || 'NO_NOTIFICATION',
          ...pick(
            this.eventCode,
            'name',
            'severity',
            'disabled',
            'detectionMechanism',
            'terminationPoint',
            'terminationMechanism',
            'actionUponDetection',
          ),
          descriptionTranslations: Object.fromEntries(
            allowedLanguages.map((lang) => [lang, this.eventCode[`${lang}Description`]]),
          ),
        }

        // update key
        set(data, key, newValue)

        await this.$apollo.mutate({
          mutation: eventCodeUpdate,
          variables: {
            data,
          },
          refetchQueries:
            key === 'eventDetectionPointId' && this.inModal
              ? ['eventCodeAdmin', 'serviceHintsAdminOverview']
              : ['eventCodeAdmin'],
        })

        this.$toast.success(this.$t('global.eventCodeDetail.updateEventCodeAction.success'))
        this.editModalOpen = null
      } catch (error) {
        console.error(error)
        const parsedError = error.message?.replace('GraphQL error:', '').trim()
        this.$toast.error(
          parsedError === 'EVENT_CODE_UPDATE_NAME_TAKEN'
            ? this.$t('global.nameTakenError')
            : this.$t('global.eventCodeDetail.updateEventCodeAction.failure'),
        )
      }
    },
    async assignCoreCause(newCoreCauseId) {
      try {
        await this.$apollo.mutate({
          mutation: eventCodeAssignCoreCause,
          variables: {
            eventCodeId: this.id,
            coreCauseId: newCoreCauseId,
          },
          refetchQueries: ['eventCodeAdmin'],
        })

        this.$toast.success(this.$t('global.eventCodeDetail.assignCoreCauseAction.success'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eventCodeDetail.assignCoreCauseAction.failure'))
      }
    },
    async deleteEventCode() {
      try {
        await this.$apollo.mutate({
          mutation: eventCodeDelete,
          variables: {
            eventCodeId: this.id,
          },
          refetchQueries: this.inModal ? ['serviceHintsAdminOverview'] : [],
        })

        this.$toast.success(this.$t('global.eventCodeDetail.deleteEventCodeAction.success'))
        if (this.inModal) {
          this.$emit('onDelete')
        } else {
          this.$router.push({ name: 'AdminEventCodes' })
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eventCodeDetail.deleteEventCodeAction.failure'))
      }
    },
    async unassignCoreCause() {
      try {
        await this.$apollo.mutate({
          mutation: eventCodeUnassignCoreCause,
          variables: {
            eventCodeId: this.id,
            coreCauseId: this.coreCauseToUnassign.id,
          },
          refetchQueries: ['eventCodeAdmin'],
        })

        this.$toast.success(this.$t('global.eventCodeDetail.unassignCoreCauseAction.success'))
        this.unassignCoreCauseConfirmationModalOpen = false
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eventCodeDetail.unassignCoreCauseAction.failure'))
      }
    },
    async reorderCoreCauses(orderedCoreCauseIds) {
      try {
        await this.$apollo.mutate({
          mutation: eventCodeReorderCoreCauses,
          variables: {
            eventCodeId: this.id,
            orderedCoreCauseIds,
          },
          refetchQueries: this.inModal ? ['serviceHintsAdminOverview'] : [],
        })

        this.$toast.success(this.$t('global.eventCodeDetail.reorderCoreCausesAction.success'))
      } catch (error) {
        console.error(error)
        this.$toast.error(this.$t('global.eventCodeDetail.reorderCoreCausesAction.failure'))
      }
    },
    async saveOrder(event) {
      const movedItem = this.coreCauses.splice(event.oldIndex, 1)[0]
      this.coreCauses.splice(event.newIndex, 0, movedItem)

      await this.reorderCoreCauses(this.coreCauses.map((cc) => cc.id))
    },
    openUnassignCoreCauseConfirmationDialog(coreCause) {
      this.coreCauseToUnassign = coreCause
      this.unassignCoreCauseConfirmationModalOpen = true
    },
  },
  computed: {
    availableCoreCauses() {
      if (!this.eventCoreCausesAdmin || !this.coreCauses) {
        return []
      }
      return this.eventCoreCausesAdmin.filter(
        (cc) => !this.coreCauses.find((assignedCoreCause) => assignedCoreCause.id === cc.id),
      )
    },
    filteredAvailableCoreCauses() {
      if (!this.availableCoreCauses.length) {
        return []
      }
      if (!this.searchAvailable || this.searchAvailable.trim() === '') {
        return this.availableCoreCauses
      }
      const lowerCaseSearch = this.searchAvailable.toLowerCase()
      return this.availableCoreCauses.filter(
        (cc) =>
          cc.name.toLowerCase().indexOf(lowerCaseSearch) !== -1 ||
          (cc.description && cc.description.toLowerCase().indexOf(lowerCaseSearch) !== -1),
      )
    },
    errorSeverities() {
      return Object.keys(errorsConfig)
    },
    allowedLanguages() {
      return allowedLanguages
    },
  },
  watch: {
    coreCauses(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.$nextTick(() => {
          Sortable.create(this.$refs.sortableCoreCauses, {
            animation: 150,
            handle: '.sort-handle',
            onUpdate: this.saveOrder,
          })
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.values-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 5px;
}

.value {
  padding: 16px;
  background-color: @color-tile-background;
  position: relative;

  &--headline {
    display: block;
    opacity: 0.5;
    font-size: 12px;
    margin-right: 20px;
  }

  &--text {
    display: block;
    font-size: 14px;
    font-weight: bold;
    word-break: break-word;
  }

  .edit-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.long-values-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  grid-gap: 5px;
}

.assignment-section {
  display: flex;

  .available-causes-wrapper {
    margin-right: 30px;
  }

  .available-cause {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px 10px 15px;
    border-radius: 3px;
    background-color: tint(@color-brand-highlight, 50%);
    margin-bottom: 5px;
    word-break: break-word;
  }

  .assigned-cause {
    display: flex;
    align-items: center;
    padding: 10px 5px 10px 15px;
    background-color: @color-brand-highlight;
    margin-bottom: 5px;
    word-break: break-word;

    & > i {
      cursor: move;
      margin-right: 15px;
    }
  }

  .texts-wrapper {
    flex-grow: 1;
    margin-right: 5px;

    .description {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 3px;
    }

    .name {
      display: block;
      font-size: 12px;
      opacity: 0.75;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .available-causes-wrapper {
      order: 2;
      margin-right: 0px;
      margin-top: 20px;
    }
  }
}
</style>
