

export default {
  menu: {
      pools: 'Baseny',
      sewagePumps: 'Pompy ściekowe',
      chargePoint: 'Urządzenie ładujące',
      companyManagement: 'Firma',
      templates: 'Szablony',
      admin: 'Administracja',
      productCatalogue: 'Produkty',
      inviteCompany: 'Zaproś firmę',
      logout: 'Wyloguj się'
  },
  deviceTypes: {
      POOL: 'Basen',
      SEWAGE_PUMP: 'Pompa ściekowa',
      CHARGE_POINT: 'Urządzenie ładujące'
  },
  device: 'Urządzenie',
  devices: 'Urządzenia',
  basicInformation: 'Podstawowe dane',
  operatingValues: 'Dane operacyjne',
  controls: 'Sterowanie',
  language: 'Język',
  lang: {
      cs: 'Čeština',
      en: 'English',
      de: 'Deutsch',
      pl: 'Polski'
  },
  daysOfWeek: {
      MONDAY: 'Poniedziałek',
      TUESDAY: 'Wtorek',
      WEDNESDAY: 'Środa',
      THURSDAY: 'Czwartek',
      FRIDAY: 'Piątek',
      SATURDAY: 'Sobota',
      SUNDAY: 'Niedziela'
  },
  daysOfWeekShort: {
      MONDAY: 'Pon',
      TUESDAY: 'Wt.',
      WEDNESDAY: 'Śr.',
      THURSDAY: 'Czw',
      FRIDAY: 'Pt.',
      SATURDAY: 'Sob.',
      SUNDAY: 'Niedz.'
  },
  reset: 'Zresetuj',
  save: 'Zapisz',
  delete: 'Usuń',
  cancel: 'Anuluj',
  discard: 'Discard',
  back: 'Powrót',
  continue: 'Kontynuuj',
  confirm: 'Potwierdź',
  enter: 'Wejście',
  accept: 'Zaakceptuj',
  close: 'Zamknij',
  submit: 'Prześlij',
  edit: 'Edytuj',
  unassign: 'Anuluj przypisanie',
  add: 'Dodaj',
  remove: 'Usuń',
  clear: 'Wyczyść',
  manage: 'Zarządzać',
  view: 'Widok',
  abort: 'Przerwij',
  ok: 'OK',
  hide: 'Ukryj',
  id: 'ID',
  email: 'Email',
  name: 'Nazwa',
  title: 'Tytuł',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  contactName: 'Osoba kontaktowa',
  contactEmail: 'E-mail kontaktowy',
  contactPhone: 'Telefon kontaktowy',
  companyName: 'Nazwa firmy',
  phoneNumber: 'Telefon',
  description: 'Opis',
  enabled: 'Aktywny',
  deactivated: 'Dezaktywowany',
  contactInfo: 'Dane kontaktowe',
  address: 'Adres',
  billingAddress: 'Adres do faktury',
  note: 'Notatka',
  set: 'Ustaw',
  location: 'Współrzędne GPS',
  map: 'Mapa',
  settings: 'Konfiguracja',
  template: 'Szablon',
  deviceName: 'Nazwa urządzenia',
  status: 'Status zamówienia',
  state: 'Stan',
  defaultTimeSchedule: 'Domyślny harmonogram czasowy',
  myDevices: 'Moje urządzenia',
  product: 'Produkt',
  endUserProduct: 'Produkt użytkownika końcowego',
  professionalUserProduct: 'Produkt użytkownika profesjonalnego',
  customer: 'Klient',
  company: 'Firma',
  type: 'Typ',
  companyAccess: 'Dostęp firmy',
  myAccess: 'Mój dostęp',
  password: {
      notSame: 'Hasła nie są identyczne.',
      notValid: 'Hasło musi zawierać co najmniej 8 znaków, 1 cyfrę, małe i duże litery oraz co najmniej jeden znak specjalny.'
  },
  countryCodes: {
      al: 'Albania',
      ad: 'Andora',
      am: '',
      at: 'Austria',
      az: 'Azerbejdżan',
      by: 'Białoruś',
      be: 'Belgia',
      ba: 'Bośnia i Hercegowina',
      bg: 'Bułgaria',
      hr: 'Chorwacja',
      cy: 'Cypr',
      cz: 'Czechy',
      dk: 'Dania',
      ee: 'Estonia',
      fi: 'Finlandia',
      fr: 'Francja',
      ge: 'Gruzja',
      de: 'Niemcy',
      gr: 'Grecja',
      hu: 'Węgry',
      is: 'Islandia',
      ie: 'Ireland',
      it: 'Włochy',
      kz: 'Kazachstan',
      lv: 'Łotwa',
      li: 'Liechtenstein',
      lt: 'Litwa',
      lu: 'Luksemburg',
      mt: 'Malta',
      md: 'Mołdawia',
      mc: 'Monako',
      me: 'Czarnogóra',
      nl: 'Holandia (Królestwo Niderlandów)',
      mk: 'Macedonia Północna',
      no: 'Norwegia',
      pl: 'Polska',
      pt: 'Portugalia',
      ro: 'Rumunia',
      ru: 'Rosja',
      sm: 'San Marino',
      rs: 'Serbia',
      sk: 'Słowacja',
      si: 'Słowenia',
      es: 'Hiszpania',
      se: 'Szwecja',
      ch: 'Szwajcaria',
      tr: 'Turcja',
      ua: 'Ukraina',
      gb: 'Wielka Brytania',
      va: 'Watykan'
  },
  addressItems: {
      route: 'Miejsce instalacji',
      city: 'Miasto',
      postalCode: 'Kod pocztowy',
      country: 'Kraj',
      lat: 'Szerokość geograficzna',
      lng: 'Długość geograficzna'
  },
  addressSpecification: 'Dane adresowe',
  addressHint: 'Wybierz adres lub wpisz go ręcznie.',
  yes: 'Tak',
  no: 'Nie',
  errors: {
      emailAlreadyExists: 'Konto użytkownika z tym adresem e-mail już istnieje. Proszę zalogować się metodą użytą podczas rejestracji.',
      invalidInvitation: 'Zaproszenie wygasło lub jest nieprawidłowe.',
      userDeactivated: 'Konto zostało dezaktywowane. Aby reaktywować swoje konto, skontaktuj się z pomocą techniczną.',
      somethingWentWrong: 'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z pomocą techniczną.'
  },
  newDeviceResetConfirmationModal: {
      heading: 'Resetuj formularz',
      text: 'Czy jesteś pewien, że chcesz przywrócić domyślne wartości?'
  },
  companyInvite: {
      inviteCompany: 'Zaproś firmę',
      description: 'Wprowadź adres e-mail administratora firmy, którą chcesz zaprosić.',
      submit: 'Wyślij zaproszenie',
      invitationSuccess: 'Zaproszenie pomyślnie wysłane.',
      invitationError: 'Nie udało się wysłać zaproszenia.',
      sentInvitations: 'Wysłane zaproszenia',
      resendInvitation: 'Wyślij ponownie'
  },
  createCompanyModal: {
      headline: 'Utwórz nową firmę',
      description: 'Wprowadź nazwę firmy.',
      companyName: 'Nazwa firmy',
      successMessage: 'Firma utworzona pomyślnie.',
      errorMessage: 'Nie udało się utworzyć firmy.',
      companyNameTakenError: 'Nazwa firmy jest zajęta. Proszę wybrać inną nazwę.'
  },
  formValidation: {
      required: 'Wymagane pole',
      notPhoneNumber: 'Wprowadź prawidłowy numer telefonu włącznie z kodem kraju.',
      notEmail: 'Podaj prawidłowy adres email.',
      notSameAsPassword: 'Hasła nie zgadzają się.',
      tooLong: 'Przekroczono maksymalną długość.',
      max: 'Wartość musi być mniejsza lub równa {max}',
      min: 'Wartość musi być większa lub równa {min}',
      integer: 'Wartość musi być liczbą całkowitą.'
  },
  pageNotFound: 'Nie znaleziono strony.',
  insufficientPermissions: 'Niewystarczające uprawnienia.',
  unsavedChanges: 'Masz niezapisane zmiany. Czy na pewno chcesz zakończyć i odrzucić zmiany?',
  on: 'Włącz',
  off: 'Wyłącz',
  copiedToClipboard: 'Skopiowane do schowka.',
  copyToClipboardFailed: 'Kopiowanie do schowka nie powiodło się.',
  all: 'Wszystko',
  doFilter: 'Filtrowanie',
  schedule: 'Czasowy harmonogram',
  schedules: 'Czasowe harmonogramy',
  hrs: 'godz.',
  dataUpdateSuccess: 'Dane zostały pomyślnie zaktualizowane.',
  dataUpdateFailure: 'Nie udało się zaktualizować danych.',
  invalidInvitationModal: {
      header: 'Nieprawidłowe zaproszenie.',
      text: 'Zaproszenie wygasło lub stało się nieprawidłowe.'
  },
  acceptCompanyToCompanyInvitationModal: {
      header: 'Zaproszenie do firmy',
      text: 'Twoja firma {childCompanyName} została zaproszona do firmy {parentCompanyName}. Akceptując to zaproszenie, {childCompanyName} stanie się partnerem serwisowym dla {parentCompanyName}. Czy chcesz zaakceptować to zaproszenie?'
  },
  acceptCompanyToCompanyInvitation: {
      success: 'Zaproszenie zostało zaakceptowane.',
      failure: 'Nie udało się zaakceptować zaproszenia.'
  },
  templateManagement: 'Zarządzanie szablonem',
  addTemplate: 'Dodaj szablon',
  emptyTemplate: 'Pusty szablon',
  dataTable: {
      itemsPerPageText: 'Wierszy na stronę:',
      allItemsText: 'Wszystko',
      of: 'z'
  },
  anonymous: 'Anonimowy',
  adminConsole: 'Konsola administratora',
  adminConsoleMenu: {
      users: 'Użytkownicy',
      devices: 'Urządzenia',
      productCatalogue: 'Katalog produktów',
      companies: 'Firmy',
      serviceHints: 'Wskazówki serwisowe',
      notificationDispatcher: 'Powiadomienia',
      thingTypes: 'Thing Types'
  },
  productCatalogueMenu: {
      devices: 'Urządzenia',
      customers: 'Klienci',
      endUserProducts: 'Produkty użytkownika końcowego',
      professionalUserProducts: 'Produkty użytkownika profesjonalnego'
  },
  addCustomer: 'Dodaj klienta',
  addEndUserProduct: 'Dodaj produkt użytkownika końcowego',
  addProfessionalUserProduct: 'Dodaj produkt użytkownika profesjonalnego',
  customerCreate: {
      success: 'Klient pomyślnie utworzony.',
      failure: 'Nie udało się utworzyć klienta.'
  },
  customerUpdate: {
      success: 'Dane klienta zostały pomyślnie zaktualizowane.',
      failure: 'Nie udało się zmienić danych klienta.'
  },
  customerDetail: 'Dane klienta',
  customerNotFound: 'Nie znaleziono klienta.',
  editCustomerData: 'Edytuj dane klienta',
  deviceType: 'Typ urządzenia',
  eligibilities: 'Uprawnienia',
  productCreate: {
      success: 'Produkt pomyślnie utworzony.',
      failure: 'Nie udało się utworzyć produktu.'
  },
  days: 'dni',
  hours: 'godziny',
  minutes: 'minuty',
  thingType: 'Typ urządzenia',
  selectThingTypesHint: 'Wybierz jeden lub więcej typów urządzeń.',
  endUserProductDetail: 'Szczegóły produktu użytkownika końcowego',
  professionalUserProductDetail: 'Szczegóły produktu użytkownika profesjonalnego',
  productNotFound: 'Nie znaleziono produktu.',
  defaultProductForType: 'Domyślny produkt dla {thingType}',
  defaultProduct: 'Domyślny produkt',
  systemProduct: 'Produkt systemowy',
  editProduct: 'Edytuj produkt',
  productUpdate: {
      success: 'Produkt został pomyślnie zaktualizowany.',
      failure: 'Nie udało się zaktualizować produktu.'
  },
  addDevice: 'Dodaj urządzenie',
  assignDeviceToCustomer: 'Przypisz urządzenie do klienta',
  deviceAssignCustomerAndProduct: {
      success: 'Urządzenie zostało pomyślnie przypisane do klienta.',
      failure: 'Nie udało się przypisać urządzenia.'
  },
  deviceRemoveCustomerAndProduct: {
      success: 'Urządzenie pomyślnie usunięte od klienta.',
      failure: 'Nie udało się usunąć urządzenia.'
  },
  deviceUnassignConfirmation: {
      heading: 'Usuń urządzenie od klienta',
      text: 'Czy na pewno chcesz usunąć „{deviceName}” ({controller}) od klienta?'
  },
  changeProduct: 'Zmień Produkt',
  deviceChangeProduct: {
      success: 'Produkt został pomyślnie zmieniony.',
      failure: 'Nie udało się zmienić produktu.'
  },
  customerDeleteConfirmation: {
      heading: 'Usuń klienta',
      text: 'Czy na pewno chcesz usunąć klienta {customerName} ({email})?'
  },
  deleteCustomer: {
      success: 'Klient został usunięty.',
      failure: 'Nie udało się usunąć klienta.'
  },
  transferDevices: 'Przenieś urządzenie do innego klienta',
  doNotTransferDevices: 'Nie przenoś urządzenia',
  productDeleteConfirmation: {
      heading: 'Usuń produkt',
      text: 'Czy na pewno chcesz usunąć produkt \'{productName}\'?'
  },
  deleteProduct: {
      success: 'Produkt został usunięty.',
      failure: 'Nie udało się usunąć produktu.'
  },
  transferDevicesProduct: 'Przenieś urządzenie na inny produkt',
  transferToDefaultProduct: 'Przenieś urządzenie do domyślnego produktu',
  setAsDefaultProduct: 'Ustaw produkt jako domyślny',
  adminProductCatalogueMenu: {
      companyEligibilities: 'Autoryzacja firm',
      deviceTypeEligibilities: 'Uprawnienia dla typów urządzeń',
      companyAllowedDeviceTypes: 'Dozwolone typy urządzeń dla firm',
      generalEligibilities: 'Ogólne uprawnienia'
  },
  companiesThingTypesUpdate: {
      success: 'Dozwolone typy urządzeń zostały pomyślnie zaktualizowane.',
      failure: 'Nie udało się zaktualizować typów urządzeń.'
  },
  allowed: 'Dozwolone',
  endUserDefaultValue: 'Domyślna wartość dla użytkownika końcowego',
  professionalUserDefaultValue: 'Domyślna wartość dla profesjonalnego użytkownika',
  generalEligibility: 'Ogólne uprawnienia',
  updateEligibility: 'Zaktualizuj uprawnienia',
  eligibility: 'Uprawnienia',
  thingTypesEligibilitiesUpdate: {
      success: 'Uprawnienia zostały pomyślnie zaktualizowane.',
      failure: 'Nie udało się zaktualizować uprawnień.'
  },
  companyAllowedEligibilitiesUpdate: {
      success: 'Dozwolone uprawnienia dla firm zaktualizowane pomyślnie.',
      failure: 'Nie udało się zaktualizować dozwolonych uprawnień dla firm.'
  },
  addEligibility: 'Dodaj uprawnienie',
  deviceEligibilityCreate: {
      success: 'Uprawnienie utworzone pomyślnie.',
      failure: 'Nie udało się utworzyć uprawnienia.'
  },
  generalEligibilitiesUpdateDefaultValues: {
      success: 'Domyślne wartości ogólnych uprawnień zostały zaktualizowane pomyślnie.',
      failure: 'Nie udało się zaktualizować domyślnych wartości uprawnień ogólnych.'
  },
  eligibilityDeleteConfirmation: {
      heading: 'Usuń uprawnienie',
      text: 'Czy na pewno chcesz usunąć uprawnienie \'{eligibility}\'?'
  },
  eligibilityDelete: {
      success: 'Uprawnienie usunięte pomyślnie.',
      failure: 'Nie udało się usunąć uprawnienia.'
  },
  addAccess: 'Dodaj Dostęp',
  addAccessConfirmation: {
      heading: 'Dodaj dostęp dla serwisu',
      text: 'Czy na pewno chcesz zezwolić firmie {childCompany} na pełny dostęp do usług serwisowych w firmie {parentCompany}?'
  },
  addCurrentCompanyAsServiceCompany: {
      success: 'Firma dodana pomyślnie.',
      failure: 'Nie udało się dodać firmy.'
  },
  removeAccess: 'Usuń Dostęp',
  removeAccessConfirmation: {
      heading: 'Usuń dostęp serwisowy',
      text: 'Czy na pewno chcesz usunąć dostęp serwisowy {childCompany} do urządzeń firmy {parentCompany}?'
  },
  removeCurrentCompanyAsServiceCompany: {
      success: 'Dostęp do usług serwisowych został pomyślnie usunięty.',
      failure: 'Nie udało się usunąć dostępu serwisowego.'
  },
  addMyselfToCompanyConfirmation: {
      heading: 'Dodaj dostęp Administratora do Firmy',
      text: 'Czy na pewno chcesz przyznać sobie dostęp administratora do firmy {companyName}?'
  },
  addMyselfToCompany: {
      success: 'Dostęp administratora przyznany pomyślnie.',
      failure: 'Nie udało się przyznać dostępu administratorowi.'
  },
  removeMyselfFromCompanyConfirmation: {
      heading: 'Usuń dostęp do Firmy',
      text: 'Czy na pewno chcesz usunąć swój dostęp do firmy {companyName}?'
  },
  removeMyCompanyAccess: {
      success: 'Dostęp usunięty pomyślnie.',
      failure: 'Nie udało się usunąć dostępu.'
  },
  invitationState: {
      accepted: 'ZAAKCEPOWANY',
      expired: 'WYGASŁO',
      pending: 'OCZEKUJĄCE NA AKCEPTACJĘ'
  },
  createCompanyInvitationDeleteConfirmation: {
      heading: 'Usuń zaproszenie',
      text: 'Jesteś pewien, że chcesz usunąć zaproszenie do {email}?'
  },
  createCompanyInvitationDelete: {
      success: 'Zaproszenie pomyślnie usunięte.',
      failure: 'Usunięcie zaproszenia nie powiodło się.'
  },
  resendCooldownTooltip: 'Akcja będzie ponownie dostępna za {remainingTime} sekund.',
  newElegibilityWarning: 'Nowe uprawnienie. To uprawnienie nie istniało w momencie tworzenia produktu i dlatego ma domyślną wartość. To powiadomienie zniknie po aktualizacji produktu.',
  invitationLanguage: 'Język Zaproszenia',
  userAccessesTab: {
      deviceAdmin: 'Administrator urządzenia',
      guests: 'Goście',
      demoTokens: 'Tokeny Demo',
      guest: 'Gość',
      activeDeviceAdminInvitation: 'Zaproszenie administratora zostało wysłane.',
      activeInvitation: 'Zaproszenie wysłane',
      noDeviceAdmin: 'Urządzenie obecnie nie ma administratora.',
      noGuests: 'Brak gości.',
      noTokens: 'Brak tokenów.',
      inviteUser: 'Zaproś Użytkownika',
      changeDeviceAdmin: 'Przenieś Urządzenie do nowego administratora',
      changeDeviceAdminModal: {
          headline: 'Przenieś Urządzenie do nowego administratora',
          text: 'To natychmiast zmieni Twoją rolę do roli gościa i wyśle wiadomość e-mail z zaproszeniem do nowego administratora Urządzenia.',
          selectExistingUser: 'Wybierz nowego administratora z użytkowników gości.',
          enterEmail: 'Wprowadź adres e-mail.'
      },
      confirmChangeDeviceAdminModal: {
          headline: 'Czy na pewno chcesz przenieść Urządzenie do nowego administratora?',
          text: 'Czy na pewno chcesz przenieść rolę administratora dla Urządzenia {deviceName} do użytkownika z adresem e-mail {invitationEmail}? To wydarzenie natychmiast zmieni twoją rolę na rolę gościa i wyśle wiadomość e-mail z zaproszeniem do nowego administratora urządzenia.'
      },
      inviteUserModal: { headline: 'Zaproś nowego użytkownika' },
      confirmDeleteAccessModal: {
          headline: 'Usuń dostęp użytkownika',
          text: 'Czy na pewno chcesz usunąć dostęp do {deviceName} dla {email}?'
      },
      editModal: {
          text: 'Przy zmianie roli z gościa na administratora, poprzedni administrator zostanie zmieniony w gościa. Urządzenia mogą mieć tylko jednego administratora.',
          role: 'Rola',
          removeUsersAccess: 'Usuń Dostęp'
      },
      removeOwnAccessModal: {
          headline: 'Usuń Mój dostęp',
          text: 'Czy chcesz przenieść rolę administratora urządzenia do kogoś innego?',
          deleteWithTransfer: 'Wybierz użytkownika',
          deleteWithoutTransfer: 'Usuń bez przeniesienia',
          selectExistingUser: 'Wybierz nowego administratora z użytkowników gości.',
          enterEmail: 'Wprowadź adres e-mail'
      },
      confirmRemoveOwnAccessModal: {
          headline: 'Usuń Mój dostęp',
          text: 'Czy na pewno chcesz usunąć swój dostęp do Urządzenia \'{deviceName}\' podczas przenoszenia roli administratora do {invitationEmail}?'
      },
      removeWithoutTransferConfirmationModal: {
          headline: 'Usuń mój dostęp bez przenoszenia',
          text: 'Czy na pewno chcesz usunąć swój dostęp do Urządzenia, \'{deviceName}\', bez przenoszenia roli administratora do innego użytkownika?'
      },
      addDemoTokenModal: {
          headline: 'Dodaj token Demo',
          submitBtnText: 'Dodaj Token'
      },
      inviteUserAction: {
          success: 'Użytkownik został pomyślnie zaproszony.',
          failure: 'Nie udało się wysłać zaproszenia do użytkownika.',
          alreadyInvited: 'Użytkownik został już zaproszony.',
          alreadyHasAccess: 'Użytkownik ma już dostęp.'
      },
      transferDeviceAdminAction: {
          success: 'Nowy administrator Urządzenia został pomyślnie zaproszony.',
          failure: 'Nie udało się przenieść Urządzenia do nowego administratora.',
          alreadyDeviceAdmin: 'Ten użytkownik jest już administratorem Urządzenia.'
      },
      deleteUserAccessAction: {
          success: 'Dostęp użytkownika został pomyślnie usunięty.',
          failure: 'Nie udało się usunąć dostępu użytkownika.'
      },
      changeAccessTypeAction: {
          success: 'Rola użytkownika została pomyślnie zaktualizowana.',
          failure: 'Nie udało się zaktualizować roli użytkownika.'
      },
      createDemoTokenAction: {
          success: 'Token demo został pomyślnie utworzony.',
          failure: 'Nie udało się utworzyć tokenu demo.'
      },
      wifiPassword: 'Dane uwierzytelniające Wi-Fi'
  },
  troubleshooting: 'Rozwiązywanie problemów',
  possibleCauses: 'Możliwe przyczyny',
  correctiveMeassures: 'Sugerowane czynności',
  changeServiceCompanyModal: {
      title: 'Zmień firmę serwisową',
      description: 'Wprowadź adres e-mail administratora firmy, którą chcesz zaprosić. Zaproszenie zostanie wysłane na ten adres e-mail. Twoja firma serwisowa zmieni się tylko wtedy, gdy firma przyjmie Twoje zaproszenie.',
      activeInvitationDescription: 'To urządzenie ma aktywne zaproszenie do nowej firmy serwisowej. Jeśli chcesz wysłać zaproszenie do innej firmy, musisz usunąć istniejące zaproszenie.',
      submit: 'Wyślij zaproszenie',
      resendInvitation: 'Wyślij ponownie zaproszenie',
      deleteInvitation: 'Usuń zaproszenie',
      activeInvitation: 'Aktywne zaproszenie',
      invitationWillExpire: 'Zaproszenie wygaśnie',
      inviteServiceCompanyAction: {
          success: 'Firma serwisowa została pomyślnie zaproszona.',
          failure: 'Nie udało się wysłać zaproszenia do firmy serwisowej.',
          notAdminEmail: 'Podany adres e-mail nie należy do administratora żadnej firmy.',
          alreadyServiceCompany: 'Firma jest już firmą serwisową dla tego urządzenia.'
      },
      resendInvitationSuccess: 'Ponowne wysłanie zaproszenia powiodło się.',
      deleteServiceCompanyInvitationAction: {
          success: 'Zaproszenie pomyślnie usunięte.',
          failure: 'Usunięcie zaproszenia nie powiodło się.'
      }
  },
  serviceCompanyInvitationAcceptationModal: {
      title: 'Zaproszenie do dostępu serwisowego dla urządzenia',
      step1: {
          title: 'Zaakceptuj lub Odrzuć',
          accepted: 'Zaakceptowane',
          description: 'Twoja firma {companyName}, została zaproszona przez użytkownika {invitationFromEmail} do obsługi serwisowej urządzenia {deviceName}({controller}). Aby zaakceptować zaproszenie, kliknij przycisk Kontynuuj i wypełnij wymagane dane.',
          rejectInvitation: 'Odrzuć zaproszenie'
      },
      step2: {
          title: 'Wybierz Klienta',
          createNewCustomer: 'Utwórz nowego Klienta',
          submitBtn: 'Dokończ'
      },
      rejectInvitationAction: {
          success: 'Zaproszenie odrzucone pomyślnie.',
          failure: 'Nie udało się odrzucić zaproszenia.'
      },
      acceptInvitationAction: {
          success: 'Zaproszenie zostało pomyślnie zaakceptowane.',
          failure: 'Nie udało się zaakceptować zaproszenia.'
      },
      addProductCataloguePermissionAction: {
          success: 'Przypisano Ci uprawnienia do katalogu produktów, aby móc zakończyć tę operację.',
          failure: 'Nie udało się zaakceptować zaproszenia.'
      }
  },
  eventCode: 'Kod zdarzenia',
  events: 'Zdarzenia',
  detectionPoint: 'Miejsce wykrycia',
  detectionPoints: 'Miejsca wykrycia',
  coreCause: 'Źródła problemów',
  coreCauses: 'Źródła problemów',
  correctiveAction: 'Działanie naprawcze',
  correctiveActions: 'Działania naprawcze',
  nameTakenError: 'Nazwa jest już zajęta.',
  adminEventCode: {
      severity: 'Powaga zgłoszenia',
      description: 'Opis',
      disabled: 'Wyłączony',
      active: 'Aktywny',
      detectionPoint: 'Miejsce wykrycia',
      detectionMechanism: 'Mechanizm wykrywania',
      terminationPoint: 'Punkt zakończenia',
      terminationMechanism: 'Mechanizm zakończenia',
      actionUponDetection: 'Działanie w przypadku wykrycia',
      availableCauses: 'Dostępne przyczyny',
      assignedCauses: 'Przypisane przyczyny'
  },
  adminEventCodes: {
      addEventCode: 'Dodaj kod wydarzenia',
      createEventCodeAction: {
          success: 'Kod zdarzenia został pomyślnie utworzony.',
          failure: 'Nie udało się utworzyć kodu zdarzenia.'
      }
  },
  adminDetectionPoints: {
      addDetectionPoint: 'Dodaj punkt detekcji',
      updateDetectionPoint: 'Aktualizuj punkt detekcji',
      createDetectionPointAction: {
          success: 'Punkt detekcji został utworzony pomyślnie.',
          failure: 'Nie udało się utworzyć punktu detekcji.'
      },
      deleteConfirmationModal: {
          heading: 'Usuń punkt detekcji',
          text: 'Czy na pewno chcesz usunąć punkt detekcji \'{detectionPoint}\'?'
      },
      deleteDetectionPointAction: {
          success: 'Punkt detekcji został pomyślnie usunięty.',
          failure: 'Nie udało się usunąć punktu detekcji.'
      },
      updateDetectionPointAction: {
          success: 'Punkt detekcji został zaktualizowany.',
          failure: 'Nie udało się zaktualizować punktu detekcji.'
      }
  },
  adminCoreCauses: {
      addCoreCause: 'Dodaj podstawową przyczynę',
      createCoreCauseAction: {
          success: 'Główna przyczyna została utworzona pomyślnie.',
          failure: 'Nie udało się utworzyć głównej przyczyny.'
      }
  },
  adminCorrectiveActions: {
      addCorrectiveAction: 'Dodaj działanie naprawcze',
      createCorrectiveActionAction: {
          success: 'Działanie naprawcze zostało pomyślnie utworzone.',
          failure: 'Nie udało się utworzyć działania naprawczego.'
      },
      deleteConfirmationModal: {
          heading: 'Usuń działanie naprawcze',
          text: 'Czy na pewno chcesz usunąć działanie naprawcze \'{correctiveAction}\'?'
      },
      deleteCorrectiveActionAction: {
          success: 'Działanie naprawcze usunięto pomyślnie.',
          failure: 'Nie udało się usunąć działania naprawczego.'
      },
      updateCorrectiveActionAction: {
          success: 'Działanie naprawcze zaktualizowane pomyślnie.',
          failure: 'Nie udało się zaktualizować działania naprawczego.'
      }
  },
  eventCodeDetail: {
      title: 'Szczegóły kodu zdarzenia',
      editAll: 'Edytuj wszystko',
      addCoreCause: 'Dodaj podstawową przyczynę',
      eventCodeNotFound: 'Nie znaleziono kodu zdarzenia.',
      assignCoreCauseAction: {
          success: 'Główna przyczyna przypisana pomyślnie.',
          failure: 'Nie udało się przypisać głównej przyczyny.'
      },
      reorderCoreCausesAction: {
          success: 'Główne przyczyny zostały pomyślnie uporządkowane.',
          failure: 'Zmiana kolejności źródeł problemów nie powiodła się.'
      },
      deleteConfirmationModal: {
          heading: 'Usuń kod zdarzenia',
          text: 'Czy na pewno chcesz usunąć kod zdarzenia \'{eventCode}\'?'
      },
      deleteEventCodeAction: {
          success: 'Kod zdarzenia został pomyślnie usunięty.',
          failure: 'Nie udało się usunąć kodu zdarzenia.'
      },
      unassignCoreCauseConfirmationModal: {
          heading: 'Usuń przypisanie źródła problemu',
          text: 'Czy na pewno chcesz usunąć przypisanie źródła problemu „{coreCause}”?'
      },
      unassignCoreCauseAction: {
          success: 'Pomyślnie usunięto przypisanie źródła problemu.',
          failure: 'Cofnięcie przypisania źródła problemu nie nastąpiło.'
      },
      updateEventCodeModal: { heading: 'Zaktualizuj kod zdarzenia' },
      updateEventCodeAction: {
          success: 'Kod zdarzenia został zaktualizowany.',
          failure: 'Nie udało się zaktualizować kodu zdarzenia.'
      }
  },
  eventCorrectiveActionDetail: {
      title: 'Szczegóły działań naprawczych',
      assignedToCoreCauses: 'Przypisane do tych źródeł problemów',
      correctiveActionNotFound: 'Nie znaleziono żadnych działań naprawczych.'
  },
  eventDetectionPointDetail: {
      title: 'Szczegóły punktu detekcji',
      assignedToEventCodes: 'Przypisane do tych kodów zdarzeń',
      detectionPointNotFound: 'Nie znaleziono punktu detekcji.'
  },
  eventCoreCauseDetail: {
      title: 'Szczegóły źródła problemu',
      addCorrectiveAction: 'Dodaj działanie naprawcze',
      coreCauseNotFound: 'Nie znaleziono źródła problemu.',
      availableActions: 'Dostępne działania',
      assignedActions: 'Przypisane działania',
      assignedToEventCodes: 'Przypisane do tych kodów zdarzeń',
      assignCorrectiveActionAction: {
          success: 'Przypisano działanie naprawcze.',
          failure: 'Nie udało się przypisać działań naprawczych.'
      },
      reorderCorrectiveActionsAction: {
          success: 'Pomyślnie zmieniono kolejność działań naprawczych.',
          failure: 'Nie udało się zmienić kolejności działań naprawczych.'
      },
      deleteConfirmationModal: {
          heading: 'Wyeliminuj źródło problemu',
          text: 'Czy na pewno chcesz usunąć źródło problemu „{coreCause}”?'
      },
      deleteCoreCauseAction: {
          success: 'Źródło problemu zostało pomyślnie usunięte.',
          failure: 'Nie udało się usunąć źródła problemu.'
      },
      unassignCorrectiveActionConfirmationModal: {
          heading: 'Anuluj przypisanie działań naprawczych',
          text: 'Czy na pewno chcesz cofnąć przypisanie działania naprawczego „{correctiveAction}”?'
      },
      unassignCorrectiveActionAction: {
          success: 'Akcja naprawcza została pomyślnie usunięta.',
          failure: 'Nie udało się cofnąć przypisania działania naprawczego.'
      },
      updateCoreCauseAction: {
          success: 'Źródło problemu zostało pomyślnie zaktualizowane.',
          failure: 'Nie udało się zaktualizować źródła problemu.'
      }
  },
  demoTokenTile: {
      token: 'Token',
      tokenValidUntil: 'Do',
      expired: 'Wygasły',
      neverExpires: 'Nigdy nie wygasa'
  },
  demoTokenForm: {
      tokenExpiration: 'Wygaśnięcie tokenu',
      accessExpiration: 'Wygaśnięcie dostępu',
      neverExpires: 'Nigdy nie wygasa',
      expirationDate: 'Termin ważności'
  },
  updateDemoTokenModal: {
      headline: 'Edytuj token demonstracyjny',
      updateDemoTokenAction: {
          success: 'Token demonstracyjny został zaktualizowany.',
          failure: 'Nie udało się zaktualizować tokenu demonstracyjnego'
      },
      deleteDemoTokenAction: {
          success: 'Token demonstracyjny został pomyślnie usunięty.',
          failure: 'Nie udało się usunąć tokenu demonstracyjnego.'
      }
  },
  demoTokenDeleteConfirmation: {
      headline: 'Usuń token demonstracyjny',
      text: 'Czy na pewno chcesz usunąć token demonstracyjny \'{tokenName}\'?'
  },
  noDevicesText: 'Nie masz żadnych urządzeń. Zacznij od dodania urządzenia w menu.',
  demoQrPrintTitle: 'Twój token demonstracyjny Poolmatix',
  crowdin: {
      crowdin: 'Crowdin',
      uploadTranslations: 'Wgraj nowe źródła do Crowdin',
      downloadTranslations: 'Pobierz nowe tłumaczenia z Crowdin',
      updateBase: 'Ustaw angielskie tłumaczenia jako nową podstawę',
      confirmationDialog: 'Okno dialogowe potwierdzenia',
      uploadConfirmationText: 'Czy na pewno chcesz przesłać nowe angielskie pliki źródłowe do Crowdin z tego środowiska?',
      downloadConfirmationText: 'Czy na pewno chcesz pobrać nowe tłumaczenia z Crowdin na to środowisko? Upewnij się, że w tym środowisku nie wprowadzono żadnych ostatnich zmian, które nie zostały przesłane do Crowdin. Jeśli są jakieś zmiany, które nie zostały jeszcze przesłane do Crowdin, powinieneś to zrobić w pierwszej kolejności. W przeciwnym razie możesz utracić nowe zmiany.',
      updateBaseConfirmationText: 'Czy na pewno chcesz ustawić nowe angielskie tłumaczenia w Crowdin jako nowe pliki bazowe (źródłowe) w Crowdin?',
      crowdinActionSuccess: 'Działanie zakończone pomyślnie.',
      crowdinActionFailure: 'Działanie nie powiodło się.',
      crowdinActionPartialFailure: 'Działanie częściowo nie powiodło się. Zobacz dane wyjściowe.',
      downloadOnlyDatabase: 'Ściągnij ciągi tylko do bazy danych, nie twórz żądania ściągnięcia'
  },
  notificationDispatcher: {
      notificationDispatcher: 'Dyspozytor powiadomień',
      notificationFlow: 'Przepływ powiadomień',
      delayConfiguration: 'Czas powiadomienia',
      event: 'Zdarzenie',
      notificationClass: 'Klasa powiadomień',
      firstNotification: 'Pierwsze powiadomienie',
      secondNotification: 'Drugie powiadomienie'
  },
  notificationClasses: {
      NO_NOTIFICATION: '<span style="color: #6B6B6B">BRAK POWIADOMIENIA</span>',
      END_USER_ONLY: 'tylko<span style="color: #1F97FF">UŻYTKOWNIK KOŃCOWY</span>',
      END_USER_THEN_PROFESSIONAL_USER: '<span style="color: #1F97FF">UŻYTKOWNIK KOŃCOWY</span> NASTĘPNIE <span style="color: #1867C1">UŻYTKOWNIK PROFESJONALNY</span>',
      END_USER_AND_PROFESSIONAL_USER: '<span style="color: #1F97FF">UŻYTKOWNIK KOŃCOWY</span> i<span style="color: #1867C1">UŻYTKOWNIK PROFESJONALNY</span>',
      PROFESSIONAL_USER_THEN_END_USER: '<span style="color: #1867C1">UŻYTKOWNIK PROFESJONALNY</span> a następnie <span style="color: #1F97FF">UŻYTKOWNIK KOŃCOWY</span>',
      PROFESSIONAL_USER_ONLY: '<span style="color: #1867C1">tylko UŻYTKOWNIK PROFESJONALNY</span>'
  },
  defaultNotificationClass: 'Domyślna klasa powiadomień',
  updateNotificationClassDelays: {
      success: 'Czas powiadomienia został pomyślnie zaktualizowany.',
      failure: 'Nie udało się zaktualizować czasu powiadomienia.'
  },
  serviceSequenceWizard: { deviceWentOffline: 'Urządzenie przeszło w tryb offline.' },
  abortServiceSequenceConfirmationModal: {
      heading: 'Przerwij sekwencję serwisową',
      text: 'Czy na pewno chcesz przerwać trwającą sekwencję serwisową?'
  },
  besgoValveBackwash: {
      title: 'Filtr do mycia wstecznego - Zawór Besgo',
      idle: 'Ta operacja uruchomi sekwencję płukania filtra. Czy chcesz kontynuować?',
      running: 'Wykonywana jest sekwencja płukania filtra.',
      completed: 'Zakończono sekwencję płukania filtra. (Automatycznie znika po 2 minutach).',
      failed: 'Sekwencja płukania filtra nie powiodła się.'
  },
  notResponding: 'Urządzenie nie odpowiada.',
  companyManagementMenu: {
      users: 'Użytkownicy',
      serviceCompanies: 'Firmy serwisowe',
      notifications: 'Powiadomienia',
      settings: 'Ustawienia'
  },
  companyDetail: {
      contactServicePartnerEmailTemplate: 'Szablon wiadomości e-mail umożliwiający kontakt z partnerem serwisowym',
      emailAddressesForContactEmails: 'Docelowe adresy e-mail "Kontakt z serwisem',
      emailAddressesForEventNotifications: 'Docelowe adresy e-mail powiadomień o wydarzeniach',
      targetAddresses: 'Adresy docelowe',
      targetAddressesHint: 'Wybierz spośród opcji lub dodaj nowe naciskając Enter.',
      emailSubject: 'Temat e-maila',
      emailBody: 'Treść e-maila',
      emailPreview: 'Podgląd wiadomości e-mail',
      emailVariables: 'Pole e-mail',
      emailVariablesDescription: 'Możesz użyć tych pól w szablonie e-mail. Pola zostaną wypełnione prawdziwymi wartościami.',
      invalidEmailAddresses: 'Wprowadź poprawny e-mail.',
      restoreDefaults: 'Przywróć domyślny szablon e-mail',
      restoreDefaultsConfirmationModal: {
          heading: 'Przywróć domyślny szablon e-mail',
          text: 'Czy na pewno przywrócić domyślny temat i szablon treści wiadomości e-mail?'
      },
      updateCompanyData: {
          success: 'Dane firmy zostały pomyślnie zaktualizowane.',
          failure: 'Nie udało się zaktualizować danych firmy.'
      },
      createNewEmail: 'Utwórz'
  },
  myAccount: 'Moje konto',
  changePassword: 'Zmień hasło',
  currentPassword: 'Aktualne hasło',
  newPassword: 'Nowe hasło',
  newPasswordAgain: 'Powtórz nowe hasło',
  passwordChangeAction: {
      success: 'Hasło zostało pomyślnie zaktualizowane.',
      failure: 'Nie udało się zaktualizować hasła.',
      notAuthorized: 'Hasło jest nieprawidłowe.'
  },
  acceptDeviceAccessRequest: {
      headline: 'Zaakceptuj prośbę o dostęp do urządzenia',
      success: 'Pomyślnie przyznałeś użytkownikowi {userEmail} dostęp do urządzenia „{deviceName}”.',
      invalidLink: 'Link jest nieprawidłowy.',
      requestNotFound: 'Link jest nieprawidłowy lub wygasł.',
      failure: 'Nie udało się zaakceptować dostępu do urządzenia.'
  },
  descriptions: 'Opis',
  overview: 'Przegląd',
  companyDefaultLanguage: 'Język firmy',
  serviceSequenceSendCommandFailure: 'Nie udało się wysłać polecenia do urządzenia.',
  updateCompanyContactEmails: {
      success: 'E-maile kontaktowe firmy zostały pomyślnie zaktualizowane.',
      failure: 'Nie udało się zaktualizować e-maili kontaktowych firmy.'
  },
  updateCompanyContactEmailTemplate: {
      success: 'Szablon e-maila kontaktowego do firmy został pomyślnie zaktualizowany.',
      failure: 'Nie udało się zaktualizować szablonu kontaktowego adresu e-mail firmy.'
  },
  contactEmailTemplate: 'Szablon e-maila kontaktowego',
  useDefaultConfiguration: 'Przejdź do ustawień',
  defaultConfiguration: 'Domyślny',
  customConfiguration: 'Niestandardowy',
  companySwitchToDefaultSettings: {
      success: 'Pomyślnie przełączono na ustawienie domyślne.',
      failure: 'Nie udało się przełączyć na domyślne ustawienia.'
  },
  companySwitchToCustomSettings: {
      success: 'Pomyślnie przełączono na ustawienia niestandardowe.',
      failure: 'Nie udało się przełączyć na ustawienia niestandardowe.'
  },
  defaultValue: 'Domyślna wartość',
  restoreDefaultNotificationDelaysConfirmationModal: {
      heading: 'Przywróć domyślny czas powiadamiania',
      text: 'Czy na pewno chcesz przywrócić domyślne ustawienia czasu powiadomień? Wszystkie bieżące ustawienia zostaną utracone.'
  },
  restoreDefaultNotificationClassesConfirmationModal: {
      heading: 'Przywróć domyślne klasy powiadomień',
      text: 'Czy na pewno chcesz przywrócić domyślne klasy powiadomień? Wszystkie bieżące klasy powiadomień zostaną utracone.'
  },
  updateNotificationClassForCompanyAndEventCode: {
      success: 'Klasa powiadomień została pomyślnie zaktualizowana.',
      failure: 'Nie udało się zaktualizować klasy powiadomień.'
  },
  timezone: 'Strefa czasowa',
  changeOperatingModeModal: { heading: 'Tryb pracy' },
  changeOperatingModeConfirmationModal: {
      heading: 'Zaktualizuj tryb pracy',
      text: 'Czy na pewno chcesz zaktualizować tryb pracy?'
  },
  updateOperatingModeAction: {
      success: 'Tryb pracy został pomyślnie zaktualizowany.',
      failure: 'Nie udało się zaktualizować trybu pracy.'
  },
  predefinedSchedule: 'Harmonogram Systemowy',
  mySchedule: 'Moj harmonogram',
  editingGlobalScheduleWarning: 'Edycja harmonogramu systemowego utworzy nową kopię harmonogramu.',
  newTimeSchedule: {
      daily: 'Nowy harmonogram dzienny',
      weekly: 'Nowy harmonogram tygodniowy'
  },
  timeScheduleName: 'Nazwa harmonogramu',
  timeScheduleBaseTemplate: 'Domyślny harmonogram',
  editDailyTimeScheduleName: 'Edytuj nazwę harmonogramu dziennego',
  editWeeklyTimeScheduleName: 'Edytuj nazwę harmonogramu tygodniowego',
  enterDailyTimeScheduleName: 'Wprowadź nazwę harmonogramu dziennego',
  enterWeeklyTimeScheduleName: 'Wprowadź nazwę harmonogramu tygodniowego',
  weeklyTimeScheduleDeleteConfirmationHeading: 'Usuń harmonogram tygodniowy',
  weeklyTimeScheduleDeleteConfirmationText: 'Czy na pewno chcesz usunąć tygodniowy harmonogram \'{scheduleName}\'?',
  newWeeklyTimeScheduleDeleteConfirmationHeading: 'Usuń nowy harmonogram tygodniowy',
  newWeeklyTimeScheduleDeleteConfirmationText: 'Czy na pewno chcesz usunąć ten nowy tygodniowy harmonogram?',
  dailyTimScheduleDeleteConfirmationHeading: 'Usuń harmonogram dzienny',
  dailyTimScheduleDeleteConfirmationText: 'Czy na pewno chcesz usunąć tygodniowy harmonogram \'{scheduleName}\'?',
  newDailyTimScheduleDeleteConfirmationHeading: 'Usuń nowy harmonogram dzienny',
  newDailyTimScheduleDeleteConfirmationText: 'Czy na pewno chcesz usunąć ten nowy dzienny harmonogram?',
  addDailySchedule: 'Dodaj harmonogram dzienny',
  weeklyTimeSchedules: 'Harmonogramy tygodniowe',
  dailyTimeSchedules: 'Dzienne harmonogramy',
  weeklyTimeSchedule: 'Harmonogram tygodniowy',
  dailyTimeSchedule: 'Dzienny harmonogram',
  disabledDeleteTooltipForScheduleGlobal: 'Nie można usunąć harmonogramu systemowego.',
  disabledDeleteTooltipForDailyScheduleUsed: 'Nie można usunąć używanego harmonogramu dziennego. Ten dzienny harmonogram czasu jest używany w tygodniowym harmonogramie czasu. Proszę najpierw usunąć harmonogram dzienny ze wszystkich tygodniowych harmonogramów.',
  disabledDeleteTooltipForWeeklyActive: 'Nie można usunąć aktywnego harmonogramu tygodniowego. Ten tygodniowy harmonogram jest obecnie aktywny. Najpierw aktywuj inny tygodniowy harmonogram.',
  disabledDeleteTooltipForWeeklyLast: 'Nie można usunąć ostatniego pozostałego harmonogramu tygodniowego. Musi istnieć co najmniej jeden tygodniowy harmonogram. Najpierw utwórz nowy tygodniowy harmonogram.',
  disabledDeleteTooltipForWeeklyUsed: 'Nie można usunąć używanego harmonogramu tygodniowego. Ten tygodniowy harmonogram jest używany przez niektóre urządzenia. Najpierw upewnij się, że ten tygodniowy harmonogram nie jest używany przez żadne urządzenia.',
  showMore: 'Pokaż więcej',
  showLess: 'Pokaż mniej',
  createServiceHistoryEntry: {
      success: 'Utworzono rekord.',
      failure: 'Nie udało się utworzyć rekordu.'
  },
  customNotificationClasses: 'Niestandardowe klasy powiadomień',
  noCustomClasses: 'Brak niestandardowych klas powiadomień',
  createCustomNotificationClass: 'Utwórz niestandardową klasę powiadomień',
  createCustomNotificationClassAction: {
      success: 'Pomyślnie utworzono niestandardową klasę powiadomień.',
      failure: 'Nie udało się utworzyć niestandardowej klasy powiadomień.'
  },
  deleteCustomNotificationClassAction: {
      success: 'Niestandardowa klasa powiadomień została pomyślnie usunięta.',
      failure: 'Nie udało się usunąć niestandardowej klasy powiadomień.'
  },
  canNotDeleteUsedCustomNotificationClassTooltip: 'Nie można usunąć używanej niestandardowej klasy powiadomień.',
  deleteCustomNotificationClassConfirmationModal: {
      heading: 'Usuń niestandardową klasę powiadomień',
      text: 'Czy na pewno chcesz usunąć niestandardową klasę powiadomień \'{className}\'?'
  },
  newErrorNotification: 'Nowe powiadomienie o błędzie',
  errorMessageClosed: 'Powiadomienie o błędzie zostało zamknięte',
  loadMore: 'Załaduj więcej',
  backToTop: 'Powrót do Góry',
  backToDashboard: 'Back to Dashboard',
  backToProductsList: 'Back to Products',
  serviceHistoryEntryTypes: {
      USER_NOTE: 'Wiadomości użytkownika',
      EVENT: 'Zdarzenia',
      OPERATION_COUNTER_CYCLE: 'Nowy cykl operacyjny'
  },
  filter: 'Filtr',
  discardChanges: 'Anuluj zmiany',
  continueEditing: 'Kontynuuj edycję',
  wifiCredentials: 'Dane uwierzytelniające Wi-Fi',
  wifiCredentialsNotFound: 'Nie udało się wczytać danych logowania Wi-Fi. Skontaktuj się z pomocą techniczną.',
  show: 'Pokaż',
  ssid: 'SSID',
  wifiQrCode: 'Zeskanuj kod QR, aby połączyć się z telefonem.',
  iXsolveNotifications: 'powiadomienia iXsolve',
  user: 'Użytkownik',
  chargersApiKey: 'Klucz API Ładowarki',
  noChargerApiKey: 'Obecnie nie masz żadnego klucza API.',
  hasChargerApiKey: 'Twój klucz API jest aktywny. Jeśli zgubiłeś klucz API, wygeneruj nowy.',
  deleteApiKey: 'Usuń klucz API',
  copyApiKeyWarning: 'Zapisz ten nowy klucz API. Nie będziesz mógł go ponownie wyświetlić.',
  generateNewApiKey: 'Wygeneruj nowy klucz API',
  confirmGenerateNew: 'Usuń stary klucz API i wygeneruj nowy',
  generateNewApiKeyConfirmationModal: {
      heading: 'Wygeneruj nowy klucz API ładowarki',
      text: 'Czy na pewno chcesz wygenerować nowy klucz API? Twój obecny klucz API zostanie usunięty.'
  },
  deleteApiKeyConfirmationModal: {
      heading: 'Usuń klucz API ładowarki',
      text: 'Czy na pewno chcesz usunąć klucz API ładowarki?'
  },
  generateNewApiKeyAction: {
      success: 'Nowy klucz API ładowarki został pomyślnie wygenerowany.',
      failure: 'Nie udało się wygenerować nowego klucza API ładowarki.'
  },
  deleteApiKeyAction: {
      success: 'Klucz API ładowarki został pomyślnie usunięty.',
      failure: 'Nie udało się usunąć klucza API ładowarki.'
  },
  energy: 'Zużycie energii',
  dateRangeChoice: {
      today: 'Dzisiaj',
      yesterday: 'Wczoraj',
      thisWeek: 'W tym tygodniu',
      lastWeek: 'Poprzedni tydzień',
      thisMonth: 'W tym miesiącu',
      lastMonth: 'Poprzedni miesiąc',
      last30days: 'Ostatnie 30 dni',
      last90days: 'Ostatnie 90 dni',
      thisYear: 'W tym roku',
      lastYear: 'W ubiegłym roku'
  },
  datesSelected: 'Wybrane',
  energyChartsNoData: 'Dane nie są dostępne dla wybranego okresu.',
  hourCounter: 'Liczba godzin',
  currentHourCounter: 'Aktualna liczba godzin',
  newHourCounter: 'Nowa liczba godzin',
  startCounter: 'Liczba startów',
  currentStartCounter: 'Bieżąca liczba startów',
  newStartCounter: 'Nowa liczba startowa',
  cycleStartDate: 'Początek bieżącego cyklu',
  newCycleStart: 'Start nowego cyklu',
  newOperatingCycle: 'Nowy cykl operacyjny',
  startNewCycle: 'Rozpocznij nowy cykl',
  equipmentId: 'Identyfikator bieżącego sprzętu',
  newEquipmentId: 'ID nowego sprzętu',
  equipmentType: 'Typ urządzenia',
  operationCounterNotePlaceholder: 'Notatka użytkownika',
  operationCounterCycleCreateAction: {
      success: 'Utworzono nowy cykl operacyjny.',
      failure: 'Nie udało się utworzyć nowego cyklu operacyjnego.'
  },
  noOperationCounters: 'Brak dostępnych liczników operacji.',
  serviceOrderNumber: 'Numer zamówienia.',
  assignee: 'Przypisano do',
  createdBy: 'Stworzony przez',
  createdAt: 'Data / czas',
  serviceOrderStatuses: {
      OPEN: 'Utworzony',
      IN_PROGRESS: 'W toku',
      CLOSED: 'Zamknięty'
  },
  serviceOrderText: 'Tekst',
  createServiceOrder: 'Utwórz zlecenie serwisowe',
  createServiceOrderAction: {
      success: 'Pomyślnie utworzono zlecenie serwisowe.',
      failure: 'Nie udało się utworzyć zlecenia serwisowego.'
  },
  updateServiceOrderAction: {
      success: 'Zlecenie serwisowe zostało pomyślnie zaktualizowane.',
      failure: 'Aktualizacja zlecenia serwisowego nie powiodła się.'
  },
  deleteServiceOrderAction: {
      success: 'Pomyślnie usunięto zlecenie serwisowe.',
      failure: 'Nie udało się usunąć zlecenia serwisowego.'
  },
  serviceOrder: 'Zlecenie serwisowe',
  thingData: 'Dane urządzenia',
  serviceOrderDeleteConfirmation: {
      heading: 'Usuń zlecenie serwisowe',
      text: 'Czy na pewno chcesz usunąć zlecenie serwisowe {serviceOrderNumber}?'
  },
  emailNotifications: 'Powiadomienia e-mail',
  emailNotificationsAddFirst: 'Aby włączyć powiadomienia, wprowadź przynajmniej jeden adres e-mail.',
  smsNotifications: 'Powiadomienia SMS',
  smsNotificationsAddFirst: 'Wpisz co najmniej jeden numer telefonu, aby włączyć powiadomienia.',
  pushNotifications: 'Wyskakujące powiadomienia',
  pushNotificationsText: 'Powiadomienia push są wysyłane na wszystkie urządzenia z aplikacją iXmanager, które używają tego urządzenia.',
  deviceSetEventNotificationsAction: {
      success: 'Ustawienia powiadomień zostały pomyślnie zaktualizowane.',
      failure: 'Nie udało się zaktualizować ustawień powiadomień.'
  },
  energyReports: 'Raporty dotyczące energii',
  configurationJobInProgress: 'Aktualizacja ustawień w toku',
  dataPropagationFailed: 'Aktualizacja ustawień nie powiodła się',
  configurationChanged: 'Zmiany wymagają ponownego uruchomienia urządzenia.',
  confirmDiscardChangesModal: {
      heading: 'Odrzuć zmiany',
      text: 'Czy na pewno chcesz odrzucić wszystkie niewysłane zmiany do urządzenia? Spowoduje to również odrzucenie oczekujących aktualizacji ustawień.'
  },
  deviceCancelConfigurationAction: {
      success: 'Aktualizacja ustawień anulowana pomyślnie.',
      failure: 'Nie udało się anulować aktualizacji ustawień.',
      inProgressError: 'Aktualizacja ustawień nie może być już anulowana. Poczekaj aż zostanie zakończona.'
  },
  mode: 'Tryb',
  duplicateSchedule: 'Duplikuj',
  duplicateScheduleTooltip: 'Utwórz nowy harmonogram z tego harmonogramu',
  repeat: 'Powtórz',
  daily: 'Codziennie',
  activate: 'Aktywuj',
  dailyScheduleNotUsedError: 'Każdy harmonogram dzienny musi zostać użyty przynajmniej raz w harmonogramie tygodniowym.',
  everyDayHasToBeUsedError: 'Proszę ustawić dzienny harmonogram dla każdego dnia tygodnia. Brakujące dni:',
  weeklyScheduleEmptyError: 'Harmonogram tygodniowy musi zawierać od 1 do 7 harmonogramów dziennych.',
  scheduleInvalidNameError: 'Wprowadź prawidłową nazwę harmonogramu.',
  noChangeError: 'Nie wprowadzono żadnych zmian.',
  newSchedule: 'Nowy harmonogram',
  nothingToSeeHereYet: 'Nie zdefiniowano harmonogramów tygodniowych.',
  addFirstDailyScheduleMessage: 'Proszę dodać swój pierwszy dzienny harmonogram.',
  saveWithWipModal: {
      heading: 'Niezapisany interwał',
      text: 'Niektóre interwały nie zostały zapisane. Wróć, aby dokończyć zmiany lub zapisz harmonogram z niezapisanymi interwałami lub bez.',
      saveWithWip: 'Zapisz z niezapisanymi interwałami',
      saveWithoutWip: 'Zapisz pomijając niezapisane interwały'
  },
  editDailyTimeSchedule: 'Edytuj dzienny harmonogram',
  newWeeklySchedule: 'Nowy harmonogram tygodniowy',
  newDailySchedule: 'Nowy dzienny harmonogram',
  dailySchedules: 'Dzienne harmonogramy',
  today: 'Dzisiaj',
  activeWeeklySchedule: 'Aktywny harmonogram tygodniowy',
  saveDailyConfirmationModal: {
      heading: 'Zapisz zmiany',
      text: 'Dzienny harmonogram <b>{scheduleName}</b> , który jest używany w tych tygodniowych harmonogramach. Zmiany dziennego harmonogramu będą miały wpływ na wszystkie te tygodniowe harmonogramy.'
  },
  duplicateSchedulePostfix: 'kopiuj',
  new: 'nowy',
  selectAll: 'Zaznacz wszystkie',
  systemTimeScheduleEditValidationModal: {
      heading: 'Nie można edytować harmonogramu systemowego.',
      info: 'Możesz utworzyć własną kopię harmonogramu i go edytować.',
      duplicateAction: 'Utwórz kopię'
  },
  addProduct: {
      selectDevice: 'Wybierz urządzenie',
      selectProduct: 'Wybierz produkt',
      setTemplate: 'Ustaw jako szablon'
  },
  cancelSequence: 'Anuluj sekwencję',
  saveAndActivate: 'Zapisz i aktywuj',
  xeeloIntegration: 'Integracja z Xeelo',
  addXeeloIntegration: 'Dodaj integrację z Xeelo',
  addXeeloIntegrationConfirmation: {
      heading: 'Dodaj integrację z Xeelo',
      text: 'Czy na pewno chcesz dodać integrację z Xeelo dla firmy {companyName}?'
  },
  addXeeloIntegrationAction: {
      success: 'Integracja z Xeelo została pomyślnie dodana.',
      failure: 'Nie udało się dodać integracji z Xeelo.'
  },
  paymentType: {
      ONE_OFF: 'Jednorazowo',
      SUBSCRIPTION: 'Subskrypcja'
  },
  serviceSequence: 'Sekwencja serwisowa',
  chargingStart: 'Rozpoczęcie ładowania',
  duration: 'Czas trwania',
  totalEnergy: 'Całkowita energia',
  chargingHistoryNoData: 'Brak dostępnych danych.',
  export: 'Eksport Danych',
  exportChargingHistory: 'Eksportuj historię ładowania',
  dateFrom: 'Od',
  dateTo: 'Do',
  chargingHistoryExportFailure: 'Nie udało się wyeksportować historii ładowania.',
  invalidDate: 'Nieprawidłowa data.',
  chargingHistoryDateFromHint: 'Pozostaw puste, aby rozpocząć od najstarszego dostępnego rekordu.',
  chargingHistoryDateToHint: 'Domyślna data jest dzisiaj.',
  errorInTimeScheduleConfiguration: 'Nieobsługiwana konfiguracja harmonogramu. Skontaktuj się ze sprzedawcą.',
  errorInTimeScheduleConfigUpdate: 'Nie można ukończyć zmiany systemoweho harmonogramu. Następujące urządzenia mają konfiguracje niezgodne z nowym harmonogramem: {deviceList}',
  eligibilityControl: {
      available: 'Available',
      notAvailable: 'Not Available',
      optional: 'Optional'
  },
  feature: 'Feature',
  eligibilityFilter: {
      all: 'All',
      oneOff: 'One-Off',
      subscription: 'Subscription-Based',
      serviceSequences: 'Service Sequences',
      premium: 'Premium'
  },
  creatBaseProduct: 'Create New Base Product',
  baseProduct: 'Base Product',
  selectProductTemplate: 'Select template',
  noTemplateYet: 'Produkt bazowy nie jest dostępny dla tej rodziny urządzenia.',
  createProduct: 'Add New Base Product',
  availability: 'Availability',
  newProductCreate: {
      success: 'Product settings successfully saved.',
      failure: 'Failed to save product settings.'
  },
  newProductUpdate: {
      success: 'Product settings successfully updated.',
      failure: 'Failed to update product settings.'
  },
  addBaseProduct: 'New Base Product',
  editBaseProduct: 'Edit Base Product',
  productSettings: 'Product Settings',
  availabilityBaseProduct: 'Availability in Base Product',
  defaultAvailability: 'Default Availability',
  selectBaseProduct: 'Select Base Product',
  premium: 'Premium',
  newProductCatalogueMenu: {
      thingsPage: 'Urządzenia',
      baseProductsPage: 'Produkty podstawowe'
  },
  changeProductModal: {
      title: 'Zmień produkt podstawowy',
      description: 'Zmiana produktu podstawowego usunie wszystkie ustawienia produktu. Kontynuować?'
  },
  thingTypeFamily: 'Rodzina typów urządzeń',
  premiumFeatureModalTitle: 'Włączanie funkcji Premium',
  premiumFeatureModalDesc: 'Włączają Państwo funkcję premium. Mogą obowiązywać dodatkowe opłaty. Proszę kontynuować?',
  eligibilityControlTooltip: 'Ta funkcja jest zawsze dostępna. Domyślnej dostępności nie można zmienić.',
  provisionType: 'Rodzaj świadczenia',
  updateThingType: 'Update Thing Type',
  thingTypeRelationUpdateSuccess: 'Relation update successful.',
  thingTypeRelationUpdateFail: 'Relation update failed.',
  tools: 'Narzędzia',
  deleteWeeklyScheduleInfo: 'Weekly time schedule has been deleted.',
  deleteDailyScheduleInfo: 'Daily time schedule has been deleted.',
  duplicateWeeklyScheduleInfo: 'Weekly time schedule has been duplicated.',
  duplicateDailyScheduleInfo: 'Daily time schedule has been duplicated.',
  scheduleDuplicateFailed: 'Failed to duplicate time schedule.'
};